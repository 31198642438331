//#region AUTH
export const AUTO_LOGIN_REQUEST = "AUTO_LOGIN_REQUEST";
export const AUTO_LOGIN_SUCCESS = "AUTO_LOGIN_SUCCESS";
export const AUTO_LOGIN_FAILURE = "AUTO_LOGIN_FAILURE";
export const AUTO_LOGIN_RESET = "AUTO_LOGIN_RESET";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const INSTANCE_ACTIVATION_REQUEST = "INSTANCE_ACTIVATION_REQUEST";
export const INSTANCE_ACTIVATION_SUCCESS = "INSTANCE_ACTIVATION_SUCCESS";
export const INSTANCE_ACTIVATION_FAILURE = "INSTANCE_ACTIVATION_FAILURE";

export const RUN_REFRESH_ACTION = "RUN_REFRESH_ACTION";

export const LOGIN_RESET = "LOGIN_RESET";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
//#endregion

//#region BOOK
export const SET_ACTIVE_BOOK = "SET_ACTIVE_BOOK";

export const FETCH_BOOKS_REQUEST = "FETCH_BOOKS_REQUEST";
export const FETCH_BOOKS_SUCCESS = "FETCH_BOOKS_SUCCESS";

export const DELETE_BOOKS_REQUEST = "DELETE_BOOKS_REQUEST";
export const DELETE_BOOKS_SUCCESS = "DELETE_BOOKS_SUCCESS";

export const CREATE_BOOK_REQUEST = "CREATE_BOOK_REQUEST";
export const CREATE_BOOK_FINISHED = "CREATE_BOOK_FINISHED";

export const UPDATE_BOOK_REQUEST = "UPDATE_BOOK_REQUEST";
export const UPDATE_BOOK_FINISHED = "UPDATE_BOOK_FINISHED";

export const BOOK_SORT_TOP_REQUEST = "BOOK_SORT_TOP_REQUEST";
export const BOOK_SORT_TOP_FINISHED = "BOOK_SORT_TOP_FINISHED";

export const GET_BOOK_PUBLIC_DETAIL_REQUEST = "GET_BOOK_PUBLIC_DETAIL_REQUEST";
export const GET_BOOK_PUBLIC_DETAIL_FINISHED = "GET_BOOK_PUBLIC_DETAIL_FINISHED";

export const GET_BOOK_PRIVATE_DETAIL_REQUEST = "GET_BOOK_PRIVATE_DETAIL_REQUEST";
export const GET_BOOK_PRIVATE_DETAIL_FINISHED = "GET_BOOK_PRIVATE_DETAIL_FINISHED";

export const GET_DATA_TO_CSV_EXPORT_REQUEST = "GET_DATA_TO_CSV_EXPORT_REQUEST";
export const GET_DATA_TO_CSV_EXPORT_FINISHED = "GET_DATA_TO_CSV_EXPORT_FINISHED";
//#endregion

//#region CATEGORY
export const FETCH_CATEGORIES_REQUEST = "FETCH_CATEGORIES_REQUEST";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";

export const CREATE_CATEGORY_REQUEST = "CREATE_CATEGORY_REQUEST";
export const CREATE_CATEGORY_FINISHED = "CREATE_CATEGORY_FINISHED";

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_FINISHED = "UPDATE_CATEGORY_FINISHED";

export const GET_CATEGORY_DETAIL_REQUEST = "GET_CATEGORY_DETAIL_REQUEST";
export const GET_CATEGORY_DETAIL_FINISHED = "GET_CATEGORY_DETAIL_FINISHED";

export const SET_CATEGORY_ORDERS_REQUEST = "SET_CATEGORY_ORDERS_REQUEST";
export const SET_CATEGORY_ORDERS_SUCCESS = "SET_CATEGORY_ORDERS_SUCCESS";
export const SET_CATEGORY_ORDERS_FAILURE = "SET_CATEGORY_ORDERS_FAILURE";
export const SET_CATEGORY_ORDERS_RESET = "SET_CATEGORY_ORDERS_RESET";

export const ADD_BOOKS_TO_CATEGORY_REQUEST = "ADD_BOOKS_TO_CATEGORY_REQUEST";
export const ADD_BOOKS_TO_CATEGORY_FINISHED = "ADD_BOOKS_TO_CATEGORY_FINISHED";

export const REMOVE_BOOKS_FROM_CATEGORY_REQUEST = "REMOVE_BOOKS_FROM_CATEGORY_REQUEST";
export const REMOVE_BOOKS_FROM_CATEGORY_FINISHED = "REMOVE_BOOKS_FROM_CATEGORY_FINISHED";

export const SET_CATEGORY_BOOK_ORDERS_REQUEST = "SET_CATEGORY_BOOK_ORDERS_REQUEST";
export const SET_CATEGORY_BOOK_ORDERS_SUCCESS = "SET_CATEGORY_BOOK_ORDERS_SUCCESS";
export const SET_CATEGORY_BOOK_ORDERS_FAILURE = "SET_CATEGORY_BOOK_ORDERS_FAILURE";
export const SET_CATEGORY_BOOK_ORDERS_RESET = "SET_CATEGORY_BOOK_ORDERS_RESET";
//#endregion

//#region AUTHOR
export const FETCH_AUTHORS_REQUEST = "FETCH_AUTHORS_REQUEST";
export const FETCH_AUTHORS_SUCCESS = "FETCH_AUTHORS_SUCCESS";

export const CREATE_AUTHOR_REQUEST = "CREATE_AUTHOR_REQUEST";
export const CREATE_AUTHOR_FINISHED = "CREATE_AUTHOR_FINISHED";

export const UPDATE_AUTHOR_REQUEST = "UPDATE_AUTHOR_REQUEST";
export const UPDATE_AUTHOR_FINISHED = "UPDATE_AUTHOR_FINISHED";

export const GET_AUTHOR_DETAIL_REQUEST = "GET_AUTHOR_DETAIL_REQUEST";
export const GET_AUTHOR_DETAIL_SUCCESS = "GET_AUTHOR_DETAIL_SUCCESS";
export const GET_AUTHOR_DETAIL_FAILURE = "GET_AUTHOR_DETAIL_FAILURE";
//#endregion

//#region COLLECTION
export const FETCH_COLLECTIONS_REQUEST = "FETCH_COLLECTIONS_REQUEST";
export const FETCH_COLLECTIONS_SUCCESS = "FETCH_COLLECTIONS_SUCCESS";

export const GET_COLLECTION_DETAIL_REQUEST = "GET_COLLECTION_DETAIL_REQUEST";
export const GET_COLLECTION_DETAIL_SUCCESS = "GET_COLLECTION_DETAIL_SUCCESS";

export const CREATE_COLLECTION_REQUEST = "CREATE_COLLECTION_REQUEST";
export const CREATE_COLLECTION_FINISHED = "CREATE_COLLECTION_FINISHED";

export const UPDATE_COLLECTION_REQUEST = "UPDATE_COLLECTION_REQUEST";
export const UPDATE_COLLECTION_FINISHED = "UPDATE_COLLECTION_FINISHED";

export const SET_COLLECTION_ORDERS_REQUEST = "SET_COLLECTION_ORDERS_REQUEST";
export const SET_COLLECTION_ORDERS_SUCCESS = "SET_COLLECTION_ORDERS_SUCCESS";
export const SET_COLLECTION_ORDERS_FAILURE = "SET_COLLECTION_ORDERS_FAILURE";
export const SET_COLLECTION_ORDERS_RESET = "SET_COLLECTION_ORDERS_RESET";
//#endregion

//#region PROMOTION
export const FETCH_PROMOTIONS_REQUEST = "FETCH_PROMOTIONS_REQUEST";
export const FETCH_PROMOTIONS_SUCCESS = "FETCH_PROMOTIONS_SUCCESS";

export const CREATE_PROMOTION_REQUEST = "CREATE_PROMOTION_REQUEST";
export const CREATE_PROMOTION_FINISHED = "CREATE_PROMOTION_FINISHED";

export const UPDATE_PROMOTION_REQUEST = "UPDATE_PROMOTION_REQUEST";
export const UPDATE_PROMOTION_FINISHED = "UPDATE_PROMOTION_FINISHED";

export const FETCH_CUSTOMER_PROMOTION_LIST_REQUEST = "FETCH_CUSTOMER_PROMOTION_LIST_REQUEST";
export const FETCH_CUSTOMER_PROMOTION_LIST_FINISHED = "FETCH_CUSTOMER_PROMOTION_LIST_FINISHED";

export const GET_PROMOTION_DETAIL_REQUEST = "GET_PROMOTION_DETAIL_REQUEST";
export const GET_PROMOTION_DETAIL_SUCCESS = "GET_PROMOTION_DETAIL_SUCCESS";
export const GET_PROMOTION_DETAIL_FAILURE = "GET_PROMOTION_DETAIL_FAILURE";
//#endregion

//#region CUSTOMER
export const FETCH_CUSTOMERS_REQUEST = "FETCH_CUSTOMERS_REQUEST";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";

export const CONFIRM_EMAIL_REQUEST = "CONFIRM_EMAIL_REQUEST";
export const CONFIRM_EMAIL_SUCCESS = "CONFIRM_EMAIL_SUCCESS";

export const CONVERT_PREMIUM_REQUEST = "CONVERT_PREMIUM_REQUEST";
export const CONVERT_PREMIUM_SUCCESS = "CONVERT_PREMIUM_SUCCESS";

export const FETCH_CUSTOMER_BASIC_INFO_REQUEST = "FETCH_CUSTOMER_BASIC_INFO_REQUEST";
export const FETCH_CUSTOMER_BASIC_INFO_FINISHED = "FETCH_CUSTOMER_BASIC_INFO_FINISHED";

export const FETCH_CUSTOMER_TAB_INFO_REQUEST = "FETCH_CUSTOMER_TAB_INFO_REQUEST";
export const FETCH_CUSTOMER_TAB_INFO_FINISHED = "FETCH_CUSTOMER_TAB_INFO_FINISHED";

export const FETCH_FEEDBACKS_REQUEST = "FETCH_FEEDBACKS_REQUEST";
export const FETCH_FEEDBACKS_FINISHED = "FETCH_FEEDBACKS_FINISHED";
//#endregion

//#region AVATAR
export const FETCH_AVATARS_REQUEST = "FETCH_AVATARS_REQUEST";
export const FETCH_AVATARS_SUCCESS = "FETCH_AVATARS_SUCCESS";

export const GET_AVATAR_DETAIL_REQUEST = "GET_AVATAR_DETAIL_REQUEST";
export const GET_AVATAR_DETAIL_SUCCESS = "GET_AVATAR_DETAIL_SUCCESS";

export const CREATE_AVATAR_REQUEST = "CREATE_AVATAR_REQUEST";
export const CREATE_AVATAR_FINISHED = "CREATE_AVATAR_FINISHED";

export const UPDATE_AVATAR_REQUEST = "UPDATE_AVATAR_REQUEST";
export const UPDATE_AVATAR_FINISHED = "UPDATE_AVATAR_FINISHED";
//#endregion

//#region ANALYTICS
export const GET_CUSTOMER_ANALYTICS = "GET_CUSTOMER_ANALYTICS";
export const GET_CUSTOMER_ANALYTICS_SUCCESS = "GET_CUSTOMER_ANALYTICS_SUCCESS";
export const GET_CUSTOMER_ANALYTICS_FINISHED = "GET_CUSTOMER_ANALYTICS_FINISHED";

export const GET_IAP_ANALYTICS = "GET_IAP_ANALYTICS";
export const GET_IAP_ANALYTICS_SUCCESS = "GET_IAP_ANALYTICS_SUCCESS";
export const GET_IAP_ANALYTICS_FINISHED = "GET_IAP_ANALYTICS_FINISHED";

export const GET_BOOK_ANALYTICS = "GET_BOOK_ANALYTICS";
export const GET_BOOK_ANALYTICS_SUCCESS = "GET_BOOK_ANALYTICS_SUCCESS";
export const GET_BOOK_ANALYTICS_FINISHED = "GET_BOOK_ANALYTICS_FINISHED";
//#endregion

//#region FEATURED
export const FETCH_FEATURED_LIST_REQUEST = "FETCH_FEATURED_LIST_REQUEST";
export const FETCH_FEATURED_LIST_SUCCESS = "FETCH_FEATURED_LIST_SUCCESS";
//#region SETTINGS
export const FETCH_LANGUAGE_CONFIGS_REQUEST = "FETCH_LANGUAGE_CONFIGS_REQUEST";
export const FETCH_LANGUAGE_CONFIGS_SUCCESS = "FETCH_LANGUAGE_CONFIGS_SUCCESS";

export const UPDATE_LANGUAGE_CONFIG_REQUEST = "UPDATE_LANGUAGE_CONFIG_REQUEST";
export const UPDATE_LANGUAGE_CONFIG_SUCCESS = "UPDATE_LANGUAGE_CONFIG_SUCCESS";
//#endregion

// TODO: ADD REQUEST SUCCESS FAIL ve dolar isareti kullan

export const RE_RENDER_AFTER_LANGUAGE_CHANGED = "RE_RENDER_AFTER_LANGUAGE_CHANGED";
export const CACHE_FLUSH_REQUEST = "CACHE_FLUSH_REQUEST";
export const GET_CACHE_KEYS_REQUEST = "GET_CACHE_KEYS_REQUEST";
export const CACHE_FLUSH_FINISHED = "CACHE_FLUSH_FINISHED";
export const CACHE_FLUSH_WITH_KEYS_REQUEST = "CACHE_FLUSH_WITH_KEYS_REQUEST";
export const CACHE_FLUSH_WITH_KEYS_FINISHED = "CACHE_FLUSH_WITH_KEYS_FINISHED";
export const GET_CACHE_KEYS_FINISHED = "GET_CACHE_KEYS_FINISHED";
