import React from "react";
import { Switch, Tooltip } from "antd";
import useRedux from "../../hooks/useRedux";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

export default function BykActivationSwitch({
  _id,
  isActive,
  baseEndpoint,
  refreshActions,
  style = {},
  disabled,
  messagePrefix,
}) {
  const { dispatchAction, $ } = useRedux();

  return (
    <Tooltip placement="top" title={isActive ? "Active" : "Not Active"}>
      <Switch
        disabled={disabled}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        style={style}
        key={`switch-${_id}`}
        checked={isActive}
        onClick={() =>
          dispatchAction($.INSTANCE_ACTIVATION_REQUEST, {
            baseEndpoint,
            isActive,
            messagePrefix,
            refreshActions,
            _id,
          })
        }
      />
    </Tooltip>
  );
}
