import { Col, Row } from "antd";
import React, { useState } from "react";
import BykFormItem from "../../../components/BykFormItem";
import BykUpload from "../../../components/BykUpload";
import BykBookImage from "../../../components/BykBookImage";
import BykCoverImage from "../../../components/BykCoverImage";
import BykBookVideo from "../../../components/BykBookVideo";
import usePermit from "../../../hooks/usePermit";
import { CAN, D as DOMAINS } from "../../../config/permission";
import { useSelector } from "react-redux";

function BookImageAndVideoUploadFormItems({ onLoadedVideoMetadata }) {
  const canUploadImage = usePermit({
    domain: DOMAINS.book,
    can: CAN.IMAGE_UPLOAD,
  });
  const canUploadVideo = usePermit({
    domain: DOMAINS.book,
    can: CAN.VIDEO_UPLOAD,
  });

  const [coverImageUrl, setCoverImageUrl] = useState("");
  const [higlightedImageUrl, setHiglightedImageUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [previewVideoUrl, setPreviewVideoUrl] = useState("");

  const activeBook = useSelector((state) => state.book.activeBook);

  const normFile = (e) => {
    if (e?.fileList?.length === 0) return null;
    else return e?.file;
  };

  function onFile(e, setter) {
    if (e.fileList.length === 0) {
      setter("");
      return;
    }

    const file = e.file;

    const URL_CLASS = window.URL || window.webkitURL;
    const fileURL = URL_CLASS.createObjectURL(file);

    if (file.type.includes("video")) {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = function () {
        const duration = video.duration;

        onLoadedVideoMetadata(file.name, duration);
      };

      video.src = fileURL;
    }

    setter(fileURL);
  }

  return (
    <Row style={{ marginBottom: 0, paddingBottom: 0 }} justify="space-between" align="middle">
      <Col>
        <BykFormItem label="Cover Image">
          <BykBookImage
            size="small"
            image={coverImageUrl || activeBook?.imageCover}
            containerStyle={{ marginTop: 6, marginBottom: 12 }}
          />

          <BykFormItem name="coverImage" valuePropName="file" getValueFromEvent={normFile} noStyle>
            <BykUpload
              btnText="Upload Cover Image"
              onChange={(e) => onFile(e, setCoverImageUrl)}
              accept="image/*"
              disabled={!canUploadImage}
            />
          </BykFormItem>
        </BykFormItem>
      </Col>

      <Col>
        <BykFormItem label="Highlighted Image">
          <BykCoverImage
            size="small"
            image={higlightedImageUrl || activeBook?.imageHighlighted}
            containerStyle={{ marginTop: 6, marginBottom: 12 }}
          />

          <BykFormItem name="highlightedImage" valuePropName="file" getValueFromEvent={normFile} noStyle>
            <BykUpload
              btnText="Upload Highlighted Image"
              onChange={(e) => onFile(e, setHiglightedImageUrl)}
              accept="image/*"
              disabled={!canUploadImage}
            />
          </BykFormItem>
        </BykFormItem>
      </Col>

      <Col>
        <BykFormItem label="Video">
          <BykBookVideo video={videoUrl || activeBook?.video?.location} containerStyle={{ marginBottom: 12 }} />

          <BykFormItem name="video" valuePropName="file" getValueFromEvent={normFile} noStyle>
            <BykUpload
              btnText="Upload Video"
              onChange={(e) => onFile(e, setVideoUrl, true)}
              accept="video/*"
              disabled={!canUploadVideo}
            />
          </BykFormItem>
        </BykFormItem>
      </Col>

      <Col>
        <BykFormItem label="Preview Video">
          <BykBookVideo
            video={previewVideoUrl || activeBook?.previewVideo?.location}
            containerStyle={{ marginBottom: 12 }}
          />

          <BykFormItem name="previewVideo" valuePropName="file" getValueFromEvent={normFile} noStyle>
            <BykUpload
              btnText="Upload Preview Video"
              onChange={(e) => onFile(e, setPreviewVideoUrl)}
              accept="video/*"
              disabled={!canUploadVideo}
            />
          </BykFormItem>
        </BykFormItem>
      </Col>

      <Col />
    </Row>
  );
}

export default BookImageAndVideoUploadFormItems;
