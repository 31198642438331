import { put, call, takeLatest } from "redux-saga/effects";
import * as $ from "../actionTypes";
import Api from "../../services/Api";
import { $A, showErrorMessage } from "../../utils";

const getCustomerAnalytics = function* () {
  try {
    const data = yield call(Api.customerAnalytics);

    yield put($A($.GET_CUSTOMER_ANALYTICS_SUCCESS, data));
  } catch (error) {
    showErrorMessage(error, "Customer analytics failed!");
  }

  yield put($A($.GET_CUSTOMER_ANALYTICS_FINISHED));
};

const getIAPAnalytics = function* () {
  try {
    const data = yield call(Api.iapAnalytics);

    yield put($A($.GET_IAP_ANALYTICS_SUCCESS, data));
  } catch (error) {
    showErrorMessage(error, "Iap analytics failed!");
  }

  yield put($A($.GET_IAP_ANALYTICS_FINISHED));
};

const getBookAnalytics = function* () {
  try {
    const data = yield call(Api.bookAnalytics);

    yield put($A($.GET_BOOK_ANALYTICS_SUCCESS, data));
  } catch (error) {
    showErrorMessage(error, "Book analytics failed!");
  }

  yield put($A($.GET_BOOK_ANALYTICS_FINISHED));
};

// prettier-ignore
export default function* saga() {
  yield takeLatest($.GET_CUSTOMER_ANALYTICS, getCustomerAnalytics);
  yield takeLatest($.GET_IAP_ANALYTICS, getIAPAnalytics);
  yield takeLatest($.GET_BOOK_ANALYTICS, getBookAnalytics);
}
