import styled from "styled-components";

export default styled.div`
  height: 100vh;
  background: ${({ theme }) => theme.login.fallbackColor};
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.login.linear && theme.login.linear.join(",")}
  );
`;
