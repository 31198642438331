import { put, call, takeLatest } from "redux-saga/effects";
import * as $ from "../actionTypes";
import Api from "../../services/Api";
import { $A, showErrorMessage, showSuccessMessage } from "../../utils";

const tryActivationSaga = function* ({ payload }) {
  const { baseEndpoint, isActive, refreshActions, messagePrefix } = payload;

  try {
    if (!baseEndpoint || typeof isActive !== "boolean") {
      throw new Error("baseEndpoint is null or isActive is not bool.");
    }

    yield call(isActive ? Api.deActivateInstance : Api.activateInstance, payload);

    yield put($A($.INSTANCE_ACTIVATION_SUCCESS));
    showSuccessMessage(`${messagePrefix || ""}${isActive ? "Deactivated" : "Activated"}`, 5);
  } catch (error) {
    console.log("🔺  RAM - error", error);
    yield put($A($.INSTANCE_ACTIVATION_FAILURE));
    showErrorMessage(error, "Instance Activation Failed!", messagePrefix || "", 5);
  }

  if (Array.isArray(refreshActions) && refreshActions.length > 0) {
    for (let i = 0; i < refreshActions.length; i++) {
      yield put(refreshActions[i]);
    }
  }
};

const tryRunRefreshSaga = function* ({ payload }) {
  const { refreshActions } = payload;
  if (Array.isArray(refreshActions) && refreshActions.length > 0) {
    for (let i = 0; i < refreshActions.length; i++) {
      yield put(refreshActions[i]);
    }
  }
};

const tryGetDataToCsvExportSaga = function* ({ payload }) {
  try {
    if (payload) {
      const data = yield call(Api.getDataToCsvExport, payload);
      yield put($A($.GET_DATA_TO_CSV_EXPORT_FINISHED, data));
    }
  } catch (error) {
    yield put($A($.GET_DATA_TO_CSV_EXPORT_FINISHED));
    console.log("TCL: error", error);
  }
};

export default function* generalSaga() {
  yield takeLatest($.INSTANCE_ACTIVATION_REQUEST, tryActivationSaga);
  yield takeLatest($.RUN_REFRESH_ACTION, tryRunRefreshSaga);
  yield takeLatest($.GET_DATA_TO_CSV_EXPORT_REQUEST, tryGetDataToCsvExportSaga);
}
