import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import Dashboard from "./Dashboard/index";
import { ROUTES, filterPermittedItems } from "../config/permission";
import BykBreadcrumb from "../components/BykBreadcrumb";
import BookList from "./Book/BookList";
import BookForm from "./Book/BookForm";
import BookDetail from "./Book/BookDetail";
import SettingsMain from "./Settings/Main";

const opacity = keyframes`
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
`;

const OpacityAnimation = styled.div`
  animation: ${opacity} 0.4s ease-in;
`;

// prettier-ignore
const routes = [
  {path: ROUTES.Dashboard, Component: Dashboard, name: ""},
  {path: ROUTES.BookList, Component: BookList, name: "Book List"},
  {path: ROUTES.BookCreate, Component: BookForm, name: "Book Create"},
  {path: ROUTES.BookId, Component: BookDetail, name: "Book Detail"},
  {path: ROUTES.BookEdit, Component: BookForm, name: "Book Edit"},
  {path: ROUTES.SettingsMain, Component: SettingsMain, name: "Settings Main"},

];

const renderRoutes = () => {
  const filteredRoutes = filterPermittedItems(routes);

  return filteredRoutes.map(({ path, Component, redirect }, key) => (
    <Route
      exact
      path={path}
      key={key}
      render={(props) => (
        <>
          <BykBreadcrumb routes={routes} {...props} />
          <OpacityAnimation key={key}>
            {redirect ? <Redirect to={redirect} /> : <Component {...props} />}
          </OpacityAnimation>
        </>
      )}
    />
  ));
};

const Routes = () => {
  return <Switch>{renderRoutes()}</Switch>;
};

export default Routes;
