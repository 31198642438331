import styled from "styled-components";

export default styled.div`
  .name {
    margin-bottom: 8px;
    color: #666;
    font-size: 30px;
    line-height: 40px;
    font-weight: bold;
    text-shadow: none;
  }
  .author {
    color: #666;
    padding: 0 !important;
    font-size: 20px;
    line-height: 40px;
    text-decoration: underline;
  }
  .title {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .row {
    margin: 10px;
    margin-top: 30px;
  }
  .read-listen-container {
    margin-top: 20px;
  }
  .block-container {
    margin-top: 10px;
    color: #666;
  }
  .colorize {
    color: #666;
  }
  .categories-gradiend-bg {
    background-image: linear-gradient(to right, #2d82d369, #37a1dc4a);
    width: auto;
    color: white;
    height: 45px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
  }
`;
