import React from "react";
import { Form } from "antd";
import XRegExp from "xregexp";
const combinedCharRegex = XRegExp.tag()`\p{M}`;

const isComponentExistInChildren = (children, component) => {
  if (Array.isArray(children)) {
    return children.some((child) => child?.type?.name === component || child?.type?.displayName === component);
  } else {
    return children?.type?.name === component || children?.type?.displayName === component;
  }
};

function BykFormItem({
  children,
  min,
  max,
  ruleForCombinedChar,
  label,
  name,
  rules = [],
  extra,
  required,
  valuePropName,
  hideWhiteSpace,

  ...props
}) {
  const allowedComponent = isComponentExistInChildren(children, "Input");

  let allRules = Array.isArray(rules) && rules.length ? [...rules] : [];
  if (!hideWhiteSpace && allowedComponent) {
    allRules.push({ whitespace: true, message: "Please delete whitespaces" });
  }
  if (required) {
    allRules.push({
      required: true,
      transform: (val) => val?.trim(),
      message: `Please add ${label.toLowerCase()}!`
    });
  }

  if (ruleForCombinedChar) {
    allRules.push({
      validator: (_, value) =>
        combinedCharRegex.test(value)
          ? Promise.resolve() //Promise.reject(new Error("There are some unindentified/broken characters, pay attention to şğüçı etc."))
          : Promise.resolve()
    });
  }
  if (min || max) allRules.push({ min, max });
  if (allRules.length === 0) allRules = undefined;

  return (
    <Form.Item label={label} name={name} valuePropName={valuePropName} rules={allRules} extra={extra} {...props}>
      {children}
    </Form.Item>
  );
}

export default BykFormItem;
