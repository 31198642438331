import React from "react";
import { SyncOutlined } from "@ant-design/icons";

import BykInfoIconTooltip from "../BykInfoIconTooltip";

import { ContainerCard, TitleContainer, ValueContainer, PrefixSuffix, ValueText } from "./styles";

function BykStatistic({ title, today, value, valueStyle = {}, prefix, suffix, isLoading, tooltip }) {
  return (
    <ContainerCard today={today}>
      <TitleContainer>
        {title}
        <BykInfoIconTooltip tooltip={tooltip} />
      </TitleContainer>

      <ValueContainer style={valueStyle}>
        <PrefixSuffix>{prefix}</PrefixSuffix>

        <ValueText> {isLoading ? <SyncOutlined spin={true} /> : value}</ValueText>
      </ValueContainer>

      <PrefixSuffix>{suffix}</PrefixSuffix>
    </ContainerCard>
  );
}

export default BykStatistic;
