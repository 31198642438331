import React from "react";
import {Button} from "antd";
import {EditOutlined} from "@ant-design/icons";
import useHistoryWithNewTab from "../../../hooks/useHistoryWithNewTab";

export default function BykEditButton({
  _id,
  size = "default",
  type = "link",
  children,
  onClick,
  baseEndpoint,
  style = {}
}) {
  const history = useHistoryWithNewTab();

  return (
    <Button
      size={size}
      style={{color: "#daac0d", ...style}}
      type={type}
      onClick={onClick ? onClick : () => history.push(`${baseEndpoint}/${_id || ""}/edit`)}>
      {children || "Edit"}
      <EditOutlined />
    </Button>
  );
}
