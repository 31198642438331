import { Form, Input, Button, Card, Row, Col } from "antd";
import React, { useEffect } from "react";
import BykLogo from "../../components/BykLogo";
import { STYLE } from "../../utils/constants";
import StyledLogin from "./styles";
import useRedux from "../../hooks/useRedux";
import { useSelector } from "react-redux";

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16
  }
};

const Login = () => {
  const { dispatchAction, $ } = useRedux();
  const { loginInProgress, autoLoginInLoading } = useSelector((state) => state.auth);
  const onFinish = (values) => {
    dispatchAction($.LOGIN_REQUEST, values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    dispatchAction($.AUTO_LOGIN_REQUEST);
  }, [dispatchAction, $]);

  if (autoLoginInLoading) return <div />;

  return (
    <StyledLogin>
      <Row justify={"center"} align={"middle"}>
        <Col span={8}></Col>
        <Col span={8}>
          <Card
            title="Sesinle Hayat Ver"
            bordered={false}
            style={{ marginTop: 150 }}
            cover={
              <div style={{ ...STYLE.flexCenter, marginTop: 10 }}>
                <BykLogo style={{ height: 150 }}></BykLogo>
              </div>
            }
          >
            <Form {...layout} name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!"
                  },
                  {
                    type: "email",
                    message: "Please validate your email!"
                  }
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!"
                  }
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button disabled={loginInProgress} loading={loginInProgress} type="primary" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={8}></Col>
      </Row>
    </StyledLogin>
  );
};

export default Login;
