import React, { useEffect } from "react";
import BykCard from "../../../components/BykCard";
import BykContainer from "../../../components/BykContainer";
import BykCacheFlushButton from "../../../components/Buttons/BykCacheFlushButton";

import { useSelector } from "react-redux";
import Styled from "./styles";
import { Checkbox } from "antd";
import BykDeleteButton from "../../../components/Buttons/BykDeleteButton";
import useRedux from "../../../hooks/useRedux";

function SettingsMain() {
  const { dispatchAction, $ } = useRedux();
  const cacheKeys = useSelector((state) => state.settings.cacheKeys);
  const cacheLoading = useSelector((state) => state.settings.cacheLoading);
  const selectedKeys = {};

  useEffect(() => {
    dispatchAction($.GET_CACHE_KEYS_REQUEST);
  }, [$, dispatchAction]);

  const handleCheckBox = (e) => {
    selectedKeys[e.target.name] = e.target.checked;
  };

  const flushSelecteds = () => {
    const keys = [];
    for (const key in selectedKeys) {
      if (selectedKeys[key]) {
        keys.push(key);
        selectedKeys[key] = false;
      }
    }
    if (keys.length) dispatchAction($.CACHE_FLUSH_WITH_KEYS_REQUEST, { keys });
  };

  return (
    <Styled>
      <BykContainer>
        <BykCard addMarginVertical>
          <BykCacheFlushButton />

          {!cacheLoading && (
            <div className="delete-cache-container">
              <div className="delete-button-container">
                <BykDeleteButton className="delete-button" onConfirm={flushSelecteds}>
                  Flush Selected
                </BykDeleteButton>
              </div>
              {cacheKeys &&
                cacheKeys.sort().map((key) => (
                  <div key={key}>
                    <Checkbox className="cache-block" name={key} onChange={handleCheckBox}>
                      <p key={key} className="config">
                        {key}
                      </p>
                    </Checkbox>
                  </div>
                ))}
            </div>
          )}
        </BykCard>
      </BykContainer>
    </Styled>
  );
}

export default SettingsMain;
