import React from "react";
import { Layout } from "antd";
import BykHeader from "./components/Layout/BykHeader";
import BykSider from "./components/Layout/BykSider";
import Routes from "./screens/Routes";
import Login from "./screens/Login";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import theme from "./theme";

const { Content } = Layout;

const App = () => {
  const loggedIn = useSelector((state) => state.auth.loggedIn);

  if (!loggedIn)
    return (
      <ThemeProvider theme={theme}>
        <Layout style={{ minHeight: "100vh" }}>
          <Login />
        </Layout>
      </ThemeProvider>
    );

  return (
    <ThemeProvider theme={theme}>
      <Layout style={{ minHeight: "100vh" }}>
        <BykSider />
        <Layout className="site-layout">
          <BykHeader />
          <Content style={{ margin: "0 16px" }}>
            <Routes />
          </Content>
        </Layout>
      </Layout>
    </ThemeProvider>
  );
};

export default App;
