import * as actionTypes from "../actionTypes";

const initialState = {
  bookList: [],
  totalBooks: 0,
  fetchBooksLoading: false,
  getBookPublicDetailLoading: false,
  getBookPrivateDetailLoading: false,
  bookFormLoading: false,
  bookPartFormLoading: false,
  bookPartDeletionLoading: false,
  bookSortSuccess: false,
  bookSortFailure: false,
  bookSortLoading: false,

  activeBook: null,
  csvData: null,
  csvDataLoading: false,
  sortToTopLoading: false,

  activeBookUserStatsLoading: false,
  activeBookUserStats: [],

  bookDeleteLoading: false
};

export default function bookReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.DELETE_BOOKS_REQUEST:
      return { ...state, bookDeleteLoading: true };
    case actionTypes.DELETE_BOOKS_SUCCESS:
      return {
        ...state,
        bookDeleteLoading: false
      };

    case actionTypes.FETCH_BOOKS_REQUEST:
      return { ...state, fetchBooksLoading: true };
    case actionTypes.FETCH_BOOKS_SUCCESS:
      return {
        ...state,
        fetchBooksLoading: false,
        bookList: payload.bookList,
        totalBooks: payload.totalBooks
      };

    case actionTypes.GET_BOOK_PUBLIC_DETAIL_REQUEST:
      return { ...state, getBookPublicDetailLoading: true };
    case actionTypes.GET_BOOK_PUBLIC_DETAIL_FINISHED:
      return {
        ...state,
        activeBook: payload || state.activeBook,
        getBookPublicDetailLoading: false
      };

    case actionTypes.GET_BOOK_PRIVATE_DETAIL_REQUEST:
      return { ...state, getBookPrivateDetailLoading: true };
    case actionTypes.GET_BOOK_PRIVATE_DETAIL_FINISHED:
      return {
        ...state,
        activeBook: payload,
        getBookPrivateDetailLoading: false
      };

    case actionTypes.SET_ACTIVE_BOOK:
      return { ...state, activeBook: payload };

    default:
      return state;
  }
}
