import React from "react";
import BykLogo from "../BykLogo";
import StyledImage from "./styles";

export default function BykCoverImage({
  image,
  size = "default",
  containerStyle,
}) {
  return (
    <StyledImage style={containerStyle}>
      {image ? (
        <img className={`${size}`} src={image} alt={`collection`} />
      ) : (
        <div className={`${size} no-img-found`}>
          <BykLogo size={size} />
        </div>
      )}
    </StyledImage>
  );
}
