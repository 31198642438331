import * as $ from "../actionTypes";

const initialState = {
  customerAnalyticsLoading: false,
  customerAnalytics: null,

  iapAnalyticsLoading: false,
  iapAnalytics: null,

  bookAnalyticsLoading: false,
  bookAnalytics: null
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case $.GET_CUSTOMER_ANALYTICS:
      return { ...state, customerAnalyticsLoading: true };
    case $.GET_CUSTOMER_ANALYTICS_SUCCESS:
      return { ...state, customerAnalytics: payload };
    case $.GET_CUSTOMER_ANALYTICS_FINISHED:
      return { ...state, customerAnalyticsLoading: false };

    case $.GET_IAP_ANALYTICS:
      return { ...state, iapAnalyticsLoading: true };
    case $.GET_IAP_ANALYTICS_SUCCESS:
      return { ...state, iapAnalytics: payload };
    case $.GET_IAP_ANALYTICS_FINISHED:
      return { ...state, iapAnalyticsLoading: false };

    case $.GET_BOOK_ANALYTICS:
      return { ...state, bookAnalyticsLoading: true };
    case $.GET_BOOK_ANALYTICS_SUCCESS:
      return { ...state, bookAnalytics: payload };
    case $.GET_BOOK_ANALYTICS_FINISHED:
      return { ...state, bookAnalyticsLoading: false };
    default:
      return state;
  }
}
