import React, { useEffect } from "react";
import { FileOutlined, BookOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { BASE_ENDPOINT } from "../../../utils/index";
import BykDetailButton from "../../Buttons/BykDetailButton";
import BykActivationSwitch from "../../BykActivationSwitch";
import BykDivider from "../../BykDivider/index";
import COLORS from "../../../utils/colors";
import BykTag from "../../BykTag";
import useQuery from "../../../hooks/useQuery";
import useRedux from "../../../hooks/useRedux";
import BykEditButton from "../../Buttons/BykEditButton";
import BykTable from "../BykTable";
import { generateColumns } from "../../../utils/generateColumns";
import PermitContainer from "../../PermitContainer";
import { D as DOMAINS, CAN } from "../../../config/permission";
import usePermit from "../../../hooks/usePermit";

export default function BykBookTable({
  hideOnSinglePage,
  isEditMode = false,
  setDisable = () => {},
  removeList,
  setRemoveList = () => {},
  defaultPageSize = 10,
  showAddToTop = false,
  defaultBooks
}) {
  const { dispatchAction, $ } = useRedux();
  const location = useLocation();

  const bookList = useSelector((state) => state.book.bookList) || defaultBooks;
  const totalBooks = useSelector((state) => state.book.totalBooks);
  const fetchBooksLoading = useSelector((state) => state.book.fetchBooksLoading);

  const search = useQuery().get("search");
  const pageQuery = useQuery().get("page") || 1;
  const limitQuery = useQuery().get("limit") || defaultPageSize;
  const isActiveFilter = useQuery().get("isActive");
  const categoryFilter = useQuery().get("category");
  const hasImageFilter = useQuery().get("hasImage");
  const hasVideoFilter = useQuery().get("hasVideo");

  const { _id } = useParams();
  const findPath = (name) => (location.pathname.split("/")[1].includes(name) ? _id : undefined);
  const author = findPath("authors");
  const isCategory = findPath("categories");
  const isCollection = findPath("collections");
  const isBookListPage = location.pathname.includes("/books");

  const canActivate = usePermit({ domain: DOMAINS.book, can: CAN.ACTIVATE });
  const canViewAuthorDetail = usePermit({
    domain: DOMAINS.author,
    can: CAN.DETAIL_VIEW
  });

  const getEndPoint = () => {
    if (isCategory) {
      return `${BASE_ENDPOINT.category}/${_id}`;
    } else if (isCollection) {
      return `${BASE_ENDPOINT.collection}/${_id}`;
    } else if (author) {
      return `${BASE_ENDPOINT.author}/${_id}`;
    } else {
      return `${BASE_ENDPOINT.book}`;
    }
  };

  useEffect(() => {
    if (isBookListPage || author) {
      dispatchAction($.FETCH_BOOKS_REQUEST, {
        page: pageQuery,
        limit: limitQuery,
        search,
        isActive: isActiveFilter,
        category: categoryFilter,
        hasImage: hasImageFilter,
        hasVideo: hasVideoFilter,
        author
      });
    }
  }, [
    dispatchAction,
    $,
    pageQuery,
    limitQuery,
    search,
    isActiveFilter,
    categoryFilter,
    author,
    isBookListPage,
    hasImageFilter,
    hasVideoFilter
  ]);

  const setRefreshType = () => {
    if (isCategory) {
      return $.GET_CATEGORY_DETAIL_REQUEST;
    } else if (isCollection) {
      return $.GET_COLLECTION_DETAIL_REQUEST;
    } else {
      return $.FETCH_BOOKS_REQUEST;
    }
  };
  const setPayload = () => {
    if (isCategory || isCollection) {
      return { _id };
    } else {
      let payload = {};
      if (pageQuery) payload.page = pageQuery;
      if (limitQuery) payload.limit = limitQuery;
      if (search) payload.search = search;
      if (isActiveFilter) payload.isActive = isActiveFilter;
      if (categoryFilter) payload.category = categoryFilter;
      if (author) payload.author = author;
      return payload;
    }
  };

  const columns = generateColumns([
    { type: "bookImage" },
    { type: "bookName" },
    {
      type: "fullNameWithLink",
      dataIndex: "author",
      baseEndpoint: BASE_ENDPOINT.author,
      canViewDetail: canViewAuthorDetail
    },
    { type: "timestamp", display: bookList?.some((e) => e.createdAt) },
    {
      title: "Status",
      dataIndex: "isActive",

      key: "table-col-status",
      render: (isActive, { _id, audio, category, partsCount, name }) => (
        <div>
          <div>
            <BykActivationSwitch
              messagePrefix={name + ": "}
              baseEndpoint={BASE_ENDPOINT.book}
              refreshActions={[
                {
                  type: setRefreshType(),
                  payload: setPayload()
                }
              ]}
              _id={_id}
              isActive={isActive}
              disabled={!canActivate}
            />
            <BykDivider width={30} />
          </div>
          <div style={{ marginBottom: 10, marginTop: 10 }}>
            {audio ? (
              <BykTag icon={<FileOutlined />} color={COLORS.tag.birthDate}>
                Audio
              </BykTag>
            ) : (
              <BykTag icon={<FileOutlined />} color={COLORS.disabledButtonText}>
                Audio
              </BykTag>
            )}

            {category === "story" ? (
              <BykTag icon={<BookOutlined />} color={COLORS.tag.updatedAt}>
                Category: {category}
              </BykTag>
            ) : (
              <BykTag icon={<BookOutlined />} color={COLORS.tag.listMaker}>
                Category: {category}
              </BykTag>
            )}
          </div>
        </div>
      )
    },
    {
      title: "",
      render: ({ _id }) => (
        <Col
          style={{
            height: 98,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
          }}
          span={24}
        >
          <Row span={24}>
            <Col span={12}>
              <PermitContainer
                permit={{
                  domain: DOMAINS.book,
                  can: CAN.DETAIL_VIEW
                }}
              >
                <BykDetailButton baseEndpoint={BASE_ENDPOINT.book} _id={_id} />
              </PermitContainer>
            </Col>
            <Col span={12}>
              <PermitContainer
                permit={{
                  domain: DOMAINS.book,
                  can: CAN.VIEW_FORM
                }}
              >
                <BykEditButton baseEndpoint={BASE_ENDPOINT.book} _id={_id} />
              </PermitContainer>
            </Col>
          </Row>
        </Col>
      ),
      key: "table-col-action"
    }
  ]);

  const rowSelection = {
    selectedRows: removeList,
    onChange: (selectedRowKeys, selectedRows) => {
      setRemoveList(selectedRows);
      setDisable(selectedRows.length > 0 ? false : true);
    }
  };

  return (
    <BykTable
      dataSource={bookList}
      rowSelection={isCategory && isEditMode ? rowSelection : false}
      loading={fetchBooksLoading}
      baseEndpoint={getEndPoint()}
      totalDocuments={totalBooks}
      columns={columns}
      defaultPageSize={defaultPageSize}
      hideOnSinglePage={hideOnSinglePage}
    />
  );
}
