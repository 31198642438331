import React from "react";
import {Button} from "antd";

function BykSubmitButton({disabled, loading}) {
  return (
    <Button disabled={disabled} type="primary" htmlType="submit" loading={loading}>
      Submit
    </Button>
  );
}

export default BykSubmitButton;
