import React from "react";
import LOGO_TEXT from "../../assets/logos.png";
import DARK from "../../assets/logo-red.png";

const HEIGHTS = {
  large: 90,
  default: 70,
  small: 50,
  xsmall: 30,
  xxsmall: 15
};

function BykLogo({ size, className = "", style = {}, withText }) {
  const heightStyle = { height: size ? HEIGHTS[size] : HEIGHTS.default };
  if (withText) {
    heightStyle.height = heightStyle.height - 7;
  }
  return (
    <img
      className={className}
      src={withText ? LOGO_TEXT : DARK}
      alt="logo"
      style={{ ...heightStyle, ...style, borderRadius: 10 }}
    />
  );
}

export default BykLogo;
