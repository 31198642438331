import { put, call, takeLatest } from "redux-saga/effects";
import * as $ from "../actionTypes";
import Api from "../../services/Api";
import { $A, BASE_ENDPOINT, showErrorMessage, showSuccessMessage } from "../../utils";

const tryFetchBookListSaga = function* ({ payload }) {
  try {
    const data = yield call(Api.fetchBooksList, payload);

    const { docs: bookList } = data;
    const totalBooks = data.totalDocs;
    yield put($A($.FETCH_BOOKS_SUCCESS, { bookList, totalBooks }));
  } catch (error) {
    showErrorMessage(error, "Could not fetch Book List");
  }
};
const tryDeleteBookSaga = function* ({ payload }) {
  try {
    yield call(Api.deleteBook, payload);

    yield put($A($.DELETE_BOOKS_SUCCESS));
    showSuccessMessage("Book Deleted: " + payload.name);
    window.history.back();
  } catch (error) {
    showErrorMessage(error, "Could not delete Book ");
  }
};

const tryCreateBookSaga = function* ({ payload }) {
  try {
    const { book, history } = payload;
    yield call(Api.createBook, book);

    yield put($A($.CREATE_BOOK_FINISHED));

    history.push(BASE_ENDPOINT.book);
    showSuccessMessage("Book Created Successfully");
  } catch (error) {
    showErrorMessage(error, "Could not create Book");
    yield put($A($.CREATE_BOOK_FINISHED));
  }
};

const tryBookSortSaga = function* ({ payload }) {
  try {
    if (payload) {
      yield call(Api.sortBook, payload);
      yield put($A($.BOOK_SORT_TOP_FINISHED));
      yield put($A($.RUN_REFRESH_ACTION, payload));
    }
  } catch (error) {
    console.log("error", error);
    showErrorMessage("Couldn't sort the list!");
    yield put($A($.BOOK_SORT_TOP_FINISHED));
  }
};


const tryGetBookPrivateDetailSaga = function* ({ payload }) {
  try {
    if (payload) {
      const data = yield call(Api.getBookPrivateDetail, payload);

      yield put($A($.GET_BOOK_PRIVATE_DETAIL_FINISHED, data));
    }
  } catch (error) {
    yield put($A($.GET_BOOK_PRIVATE_DETAIL_FINISHED));
    console.log("TCL: error", error);
    showErrorMessage(error, "Could not fetch Book");
  }
};

const tryUpdateBookSaga = function* ({ payload }) {
  try {
    const { book, history } = payload;
    yield call(Api.updateBook, book);

    yield put($A($.UPDATE_BOOK_FINISHED));

    history.goBack();
    showSuccessMessage("Book Updated Successfully");
  } catch (error) {
    showErrorMessage(error, "Could not update Book");
    yield put($A($.UPDATE_BOOK_FINISHED));
  }
};

export default function* bookSaga() {
  yield takeLatest($.FETCH_BOOKS_REQUEST, tryFetchBookListSaga);
  yield takeLatest($.DELETE_BOOKS_REQUEST, tryDeleteBookSaga);
  yield takeLatest($.CREATE_BOOK_REQUEST, tryCreateBookSaga);
  yield takeLatest($.UPDATE_BOOK_REQUEST, tryUpdateBookSaga);
  yield takeLatest($.BOOK_SORT_TOP_REQUEST, tryBookSortSaga);
  yield takeLatest($.GET_BOOK_PRIVATE_DETAIL_REQUEST, tryGetBookPrivateDetailSaga);
}
