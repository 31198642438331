import React, { useState } from "react";
import { Button, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

export default function BykDeleteButton({ size = "default", type = "link", loading, children, onConfirm, style = {} }) {
  const [visible, setVisible] = useState(false);

  const handleConfirm = () => {
    console.log("first");
    setVisible(false);

    onConfirm();
  };

  return (
    <Popconfirm
      title={`Are you sure to delete it?`}
      visible={visible}
      onConfirm={handleConfirm}
      onCancel={() => setVisible(false)}
      okButtonProps={{ loading: loading }}
      okText="Yes"
      cancelText="No"
      placement="left"
    >
      <Button
        size={size}
        onClick={() => setVisible(true)}
        style={{ color: loading ? "gray" : "#ff4d4f", ...style }}
        type={type}
      >
        {children || "Delete"}
        <DeleteOutlined />
      </Button>
    </Popconfirm>
  );
}
