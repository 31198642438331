const ENVIRONMENT = {
  DEVELOPMENT: "development",
  STAGING: "staging",
  PRODUCTION: "production",
  PREPROD: "preprod"
};
const APP = {
  development: { API_PATH: `http://localhost:5001` },
  production: { API_PATH: `https://shvapi.biryudumkitap.com` }
};
export const activeEnvironment =
  process.env.NODE_ENV === "development" ? ENVIRONMENT.DEVELOPMENT : ENVIRONMENT.PRODUCTION; // CHANGE HERE

const APP_CONFIG = APP[activeEnvironment];

export default APP_CONFIG;
