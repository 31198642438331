import APP_CONFIG from "../config/env-config";
const STATUS_NOT_SUCCESS = (status) => status < 200 || status > 399;
export default class CustomHttpService {
  fetch = async (options) => {
    const fullApiPath = `${APP_CONFIG.API_PATH}${options.endpoint}`;
    let body = options.body || {};
    const method = options.method || "get";
    const headers = options.headers || {};
    if ([undefined, null, "", "null", "undefined"].includes(headers.tokenstaff)) {
      delete headers.tokenstaff;
    }

    if (!options.isFormData) headers["Content-Type"] = "application/json";
    else {
      const formData = new FormData();
      Object.keys(body).forEach((item) => {
        const value = typeof body[item] !== "boolean" && !body[item] ? "" : body[item];
        formData.append(item, value);
      });
      body = formData;
    }
    const fetchOptions = {
      method,
      // credentials: "include",
      headers: {
        ...headers
      }
    };

    if (method === "post" || method === "patch" || method === "put") {
      fetchOptions.body = options.isFormData ? body : JSON.stringify(body);
    }

    return this._doFetch(fullApiPath, fetchOptions);
  };

  _doFetch = (fullApiPath, fetchOptions) => {
    return new Promise((resolve, reject) => {
      fetch(fullApiPath, fetchOptions)
        .then(async (res) => {
          if (STATUS_NOT_SUCCESS(res.status)) {
            const { message, error, errors } = await res.json();

            if (errors && Array.isArray(errors)) reject(errors.map((el) => el.msg).join("\n"));
            if (message) {
              if (Array.isArray(message)) {
                reject(message?.join("\n") || error);
              } else {
                reject(message || error);
              }
            } else if (error) reject(error);
            else reject();
          } else {
            const { data } = await res.json();
            resolve(data);
          }
        })
        .catch((err) => reject(err));
    });
  };
}
