import React from "react";
import Upload from "rc-upload";
import axios from "axios";
import { Progress } from "antd";
import { connect } from "react-redux";
import store from "../../redux/configureStore";
import path from "path";
import { bookImageSize3X, featuredAndCollectionImageSize2x } from "../../utils/constants";
import { TOKEN } from "../../utils";

import { Button, notification } from "antd";
import APP_CONFIG from "../../config/env-config";

const openNotificationWithIcon = (type, message) => {
  notification[type]({
    message
  });
};

class BykFileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      percent: null,
      modal: false,
      isError: false
    };
  }

  render() {
    const { style = {}, buttonText = "Upload", mediaType, model, _id, disabled } = this.props;
    const { modal } = this.state;

    return (
      <div className="p-1">
        {modal && <Progress percent={this.state.percent} />}

        <Upload
          multiple={false}
          headers={{ tokenstaff: window.localStorage.getItem(TOKEN) }}
          accept={mediaType === "image" ? ".png, .jpg, .jpeg" : ".mp3"}
          component={"div"}
          onProgress={({ percent }) => {
            this.setState({ percent });
            if (percent) {
              this.setState({ modal: true });
            }
          }}
          onError={(error) => {
            openNotificationWithIcon("error", "Error occured");

            this.setState({ percent: null });
            this.setState({ modal: false });
          }}
          onSuccess={() => {
            if (this.props.ActionType) {
              store.dispatch({
                type: this.props.ActionType,
                payload: this.props.ActionPayload || _id
              });
            }
            this.setState({ modal: false });
            openNotificationWithIcon("success", "Uploaded Successfully");
            this.setState({ percent: null });
          }}
          customRequest={(obj) => this.customRequest(obj, model, _id, mediaType, this.props)}
        >
          <Button style={style} disabled={disabled}>
            {buttonText}
          </Button>
        </Upload>
      </div>
    );
  }

  async customRequest(
    { action, data, file, filename, headers, onError, onProgress, onSuccess, withCredentials },
    model,
    _id,
    mediaType,
    props
  ) {
    try {
      let isErr = false;

      const imageMimes = ["image/jpeg", "image/jpg", "image/png"];
      const audioMimes = ["audio/mpeg", "audio/mp3"];
      const audioExt = [".mp3"];
      const imageExt = [".png", ".jpeg", ".jpg", ".JPG"];
      const MB_TO_BITES = 1000000;

      const MAX_AUDIO_SIZE = 2000 * MB_TO_BITES;
      const MAX_IMAGE_SIZE = 15 * MB_TO_BITES;
      console.log("TCL: file", file);

      if (mediaType === "audio") {
        if (!audioMimes.includes(file.type) || !audioExt.includes(path.extname(file.name))) {
          openNotificationWithIcon("error", "file type is not mp3 ");

          isErr = true;
        } else if (Number(file.size) > MAX_AUDIO_SIZE) {
          openNotificationWithIcon("error", "file size is  bigger than 2gb");
          isErr = true;
        }
        if (!isErr) {
          setTimeout(() => {
            const formData = new FormData();

            formData.append(mediaType, file);

            axios
              .post(APP_CONFIG.API_PATH + `/${model}/${_id}/${mediaType}`, formData, {
                withCredentials,
                headers,
                onUploadProgress: ({ total, loaded }) => {
                  onProgress(
                    {
                      percent: Math.round((loaded / total) * 100).toFixed(2)
                    },
                    file
                  );
                }
              })
              .then(({ data: response }) => {
                onSuccess(response, file);
              })
              .catch(onError);
          }, 1000);
        }
      } else if (mediaType === "image") {
        if (!imageMimes.includes(file.type) || !imageExt.includes(path.extname(file.name))) {
          openNotificationWithIcon("error", "file is not in valid extension");

          isErr = true;
        } else if (Number(file.size) > MAX_IMAGE_SIZE) {
          openNotificationWithIcon("error", "file size is  bigger than 15 mb");
          isErr = true;
        }

        try {
          function callback(isError) {
            console.log("RAM ~ file: index.js ~ line 117 ~ BykFileUpload ~ callback ~ isError", isError);
            if (!isError) {
              setTimeout(() => {
                const formData = new FormData();

                formData.append(mediaType, file);

                axios
                  .post(APP_CONFIG.API_PATH + `/${model}/${_id}/${mediaType}`, formData, {
                    withCredentials,
                    headers,
                    onUploadProgress: ({ total, loaded }) => {
                      onProgress(
                        {
                          percent: Math.round((loaded / total) * 100).toFixed(2)
                        },
                        file
                      );
                    }
                  })
                  .then(({ data: response }) => {
                    onSuccess(response, file);
                  })
                  .catch(onError);
              }, 1000);
            }
          }
          const ress = await getResponse({ model, props, file }, callback);
          console.log("RAM ~ file: index.js ~ line 117 ~ BykFileUpload ~ not e", ress);
          // if (isError) {
          //   throw new Error("error cikti");
          //   // window.location.reload();
          // } else {

          // }
        } catch (error) {
          console.log("RAM ~ file: index.js ~ line 139 ~ BykFileUpload ~ error", error);
        }
      }
    } catch (error) {
      console.log("TCL: error", error);
    }
  }
}

async function getResponse({ model, props, file }, callback) {
  var _URL = window.URL || window.webkitURL;
  var img = new Image();
  var isErr = false;

  img.onload = function () {
    console.log(this.width + "w  = h " + this.height);
    if (model === "books" && (bookImageSize3X.height !== this.height || bookImageSize3X.width !== this.width)) {
      openNotificationWithIcon(
        "error",
        `Book Image Size should be  W:${bookImageSize3X.width}, H:${bookImageSize3X.height}`
      );
      callback(true);

      return true;
    } else if (
      model !== "books" &&
      (featuredAndCollectionImageSize2x.height !== this.height || featuredAndCollectionImageSize2x.width !== this.width)
    ) {
      openNotificationWithIcon(
        "error",
        `Image Size should be  W:${featuredAndCollectionImageSize2x.width}, H:${featuredAndCollectionImageSize2x.height}`
      );

      callback(true);

      return true;
    } else {
      callback(false);
    }
  };
  img.src = _URL.createObjectURL(file);
  // i want to get isErr variable when the onload function is complete
  // wait the return value until the img is not isErr

  return isErr;
}

export default connect()(BykFileUpload);
