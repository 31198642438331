import React from "react";
import { Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
const { Paragraph } = Typography;

export default function BykCopyable({
  text,
  children,
  className = "",
  style = {},
  iconStyle = { marginLeft: 4, marginBottom: 2, fontSize: 16 }
}) {
  if (!text && !children) return null;
  return (
    <Paragraph
      className={className}
      style={style}
      copyable={{
        icon: [
          <FontAwesomeIcon style={iconStyle} icon={faClipboard} />,
          <FontAwesomeIcon style={iconStyle} icon={faCheck} />
        ],
        tooltips: ["copy", "copied"],
        text: text || (typeof children === "string" && children)
      }}
    >
      {children}
    </Paragraph>
  );
}
