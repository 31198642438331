import React from "react";
import { Link } from "react-router-dom";

function BykBreadcrumb(props) {
  const crumbs = props.routes
    .filter(({ path }) => props.match.path.includes(path))
    .map(({ path, ...rest }) => ({
      path: Object.keys(props.match.params).length
        ? Object.keys(props.match.params).reduce(
            (path, param) =>
              path.replace(`:${param}`, props.match.params[param]),
            path
          )
        : path,
      ...rest,
    }));

  return (
    <div style={{ top: 20, position: "absolute" }}>
      {crumbs.map(({ name, path }, key) =>
        key + 1 === crumbs.length ? (
          <span style={{ color: "white" }} key={key}>
            {name}
          </span>
        ) : (
          <Link style={{ color: "#999593" }} key={key} to={path}>
            {name} {key ? " / " : ""}
          </Link>
        )
      )}
    </div>
  );
}

export default BykBreadcrumb;
