import styled from "styled-components";

export const PageContainer = styled.div`
  padding: 20px 0px;
`;

export const SuffixContainer = styled.div`
  display: inline;
  font-size: 12px;
`;
