import { Button, Spin } from "antd";
import React from "react";
import useRedux from "../../../hooks/useRedux";
import { useSelector } from "react-redux";

function BykCacheFlushButton({ disabled }) {
  const { dispatchAction, $ } = useRedux();
  const { cacheLoading } = useSelector((state) => state.settings);

  return cacheLoading ? (
    <Spin />
  ) : (
    <Button
      type="primary"
      loading={cacheLoading}
      disabled={disabled}
      onClick={() => dispatchAction($.CACHE_FLUSH_REQUEST)}
    >
      Cache Flush (Bulk 10 Request)
    </Button>
  );
}

export default BykCacheFlushButton;
