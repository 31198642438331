import React, { useState } from "react";
import { Card, Checkbox, Col, Drawer, Row } from "antd";
import { Button, Radio, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faVideo } from "@fortawesome/free-solid-svg-icons";

import BykDivider from "../BykDivider";
import useQuery from "../../hooks/useQuery";
import BykCreateButton from "../Buttons/BykCreateButton";
import useHistoryWithNewTab from "../../hooks/useHistoryWithNewTab";
import { updateQueryString } from "../../utils";

const { Search } = Input;

const BykTableHeaderBar = ({
  baseEndpoint,
  hideCreate,
  searchPlaceholder = "Search something...",
  showActiveFilter,
  showAssetFilter
}) => {
  const history = useHistoryWithNewTab();
  const searchDefaultValue = useQuery().get("search");
  let isActiveDefault = useQuery().get("isActive");
  if (isActiveDefault === "true") {
    isActiveDefault = "Active";
  } else if (isActiveDefault === "false") {
    isActiveDefault = "InActive";
  } else {
    isActiveDefault = "All";
  }

  let categoryDefault = useQuery().get("category");
  if (categoryDefault === "story") {
    categoryDefault = "story";
  } else if (categoryDefault === "tale") {
    categoryDefault = "tale";
  } else {
    categoryDefault = "All";
  }

  const hasImageQuery = useQuery().get("hasImage");
  const hasVideoQuery = useQuery().get("hasVideo");
  let hasImageDefault = hasImageQuery === "true" ? 1 : hasImageQuery === "false" ? 2 : 0;
  let hasVideoDefault = hasVideoQuery === "true" ? 1 : hasVideoQuery === "false" ? 2 : 0;
  const [hasImage, setHasImage] = useState(hasImageDefault);
  const [hasVideo, setHasVideo] = useState(hasVideoDefault);

  const [visible, setVisible] = useState(false);
  const [activeStatus, setActiveStatus] = useState(isActiveDefault);

  const [category, setCategory] = useState(categoryDefault)

  // const showDrawer = () => {
  //   setVisible(true);
  // };

  function setImage() {
    const newImg = (hasImage + 1) % 3;
    const status = { 1: "true", 2: "false" };
    setHasImage(newImg);
    history.push(updateQueryString("hasImage", status[newImg]));
  }

  function setVideo() {
    const newVideo = (hasVideo + 1) % 3;
    const status = { 1: "true", 2: "false" };
    setHasVideo(newVideo);
    history.push(updateQueryString("hasVideo", status[newVideo]));
  }

  const onClose = () => {
    setVisible(false);
  };
  const onSearch = (value) => {
    if (value.trim()) history.push(`${baseEndpoint}?search=${value.trim()}`);
    else {
      history.push(baseEndpoint);
    }
  };
  const onApply = () => {
    history.push(`${baseEndpoint}?isActive=true`);
  };

  const plainOptions = [
    { label: "Active", value: "Active", default: true },
    { label: "Inactive", value: "Inactive", default: true }
  ];

  function onChange(checkedValues) {
    console.log("checked = ", checkedValues);
  }

  const onCreate = () => {
    history.push(`${baseEndpoint}/create`);
  };

  const onActivationChange = (e) => {
    setActiveStatus(e.target.value);
    let active;
    if (e.target.value === "InActive") {
      active = "false";
    } else if (e.target.value === "Active") {
      active = "true";
    } else active = undefined;

    const updateUrl = updateQueryString("isActive", active);
    history.push(updateQueryString("page", 1, updateUrl));
  };
  const onCategoryChange = (e) => {
    setCategory(e.target.value);
    let active;
    if (e.target.value === "story") {
      active = "story";
    } else if (e.target.value === "tale") {
      active = "tale";
    } else active = undefined;

    const updateUrl = updateQueryString("category", active);
    history.push(updateQueryString("page", 1, updateUrl));
  };

  return (
    <Card
      size={"small"}
      style={{
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 6,
        borderStyle: "solid",
        borderWidth: 1
      }}
    >
      <Row>
        <Col flex="auto">
          <Search defaultValue={searchDefaultValue} allowClear placeholder={searchPlaceholder} onSearch={onSearch} />
        </Col>
        {showActiveFilter && (
          <Col>
            <BykDivider type={"vertical"}></BykDivider>
            <Radio.Group value={activeStatus} onChange={onActivationChange}>
              <Radio.Button value="All">All</Radio.Button>
              <Radio.Button value="Active">Active</Radio.Button>
              <Radio.Button value="InActive">Inactive</Radio.Button>
            </Radio.Group>
            <BykDivider type={"vertical"}></BykDivider>
            <Radio.Group value={category} onChange={onCategoryChange}>
              <Radio.Button value="All">All</Radio.Button>
              <Radio.Button value="story">Stories</Radio.Button>
              <Radio.Button value="tale">Tales</Radio.Button>
            </Radio.Group>

          </Col>
        )}

        {showAssetFilter && (
          <Col>
            <BykDivider type={"vertical"}></BykDivider>
            <Button onClick={() => setImage()}>
              <FontAwesomeIcon icon={faImage} />

              {hasImage % 3 === 0 ? "" : hasImage % 3 === 1 ? "(+)" : "(-)"}
            </Button>
            <Button onClick={() => setVideo()}>
              <FontAwesomeIcon icon={faVideo} />

              {hasVideo % 3 === 0 ? "" : hasVideo % 3 === 1 ? "(+)" : "(-)"}
            </Button>
          </Col>
        )}

        {hideCreate ? null : (
          <Col>
            <BykDivider type={"vertical"}></BykDivider>
            <BykCreateButton onClick={onCreate} />
            <BykDivider type={"vertical"}></BykDivider>
          </Col>
        )}
      </Row>
      <Drawer
        title="Filter"
        placement="right"
        closable={true}
        mask
        onClose={onClose}
        visible={visible}
        maskClosable={true}
        footer={
          <div
            style={{
              marginRight: 10,
              marginLeft: 10,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Button>Reset</Button>{" "}
            <Button onClick={onApply} type="primary">
              Apply
            </Button>
          </div>
        }
      >
        <Checkbox.Group
          options={plainOptions}
          defaultValue={plainOptions.map((e) => e.default && e.value)}
          onChange={onChange}
        />
      </Drawer>
    </Card>
  );
};
export default BykTableHeaderBar;
