import React, { Component } from "react";
import { Editor, EditorState, convertFromRaw } from "draft-js";
class BykEditorReadOnly extends Component {
  render() {
    const { content, style = {} } = this.props;
    if (!content) return null;
    const contentState = convertFromRaw(
      Object.assign(content, { entityMap: [] })
    );
    const editorState = EditorState.createWithContent(contentState);

    return (
      <div style={style}>
        <Editor editorState={editorState} readOnly={true} />
      </div>
    );
  }
}

export default BykEditorReadOnly;
