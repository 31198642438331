import styled from "styled-components";
import { VIDEO_SIZE } from "../../utils/constants";

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .large {
    height: ${VIDEO_SIZE.large.height}px;
    width: ${VIDEO_SIZE.large.width}px;
    border-radius: 5px;
  }
  .default {
    height: ${VIDEO_SIZE.default.height}px;
    width: ${VIDEO_SIZE.default.width}px;
    border-radius: 4px;
  }
  .small {
    height: ${VIDEO_SIZE.small.height}px;
    width: ${VIDEO_SIZE.small.width}px;
    border-radius: 4px;
  }
  .xsmall {
    height: ${VIDEO_SIZE.xsmall.height}px;
    width: ${VIDEO_SIZE.xsmall.width}px;
    border-radius: 4px;
  }

  .no-video-found {
    background-color: #f626020d;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
