import React, { useEffect, useState } from "react";
import { Table } from "antd";
import useHistoryWithNewTab from "../../../hooks/useHistoryWithNewTab";
import useQuery from "../../../hooks/useQuery";

export default function BykTable({
  columns,
  loading,
  defaultPageSize,
  baseEndpoint,
  totalDocuments,
  dataSource,
  hideOnSinglePage,
  ...props
}) {
  const history = useHistoryWithNewTab();
  const search = useQuery().get("search");
  const pageQuery = parseInt(useQuery().get("page")) || 1;
  const limitQuery = parseInt(useQuery().get("limit")) || defaultPageSize;
  const isActiveFilter = useQuery().get("isActive");
  const categoryFilter = useQuery().get("category");

  const tabQuery = useQuery().get("tab");
  const relatedModelQuery = useQuery().get("relatedModel");

  const [pagination, setPagination] = useState({
    page: pageQuery,
    pageSize: limitQuery
  });

  useEffect(() => {
    setPagination({ page: pageQuery, pageSize: limitQuery });
  }, [pageQuery, limitQuery]);

  return (
    <Table
      columns={columns}
      size={"small"}
      dataSource={dataSource}
      loading={loading}
      pagination={{
        hideOnSinglePage,
        position: ["bottomCenter", "bottomRight"],
        total: totalDocuments,
        defaultCurrent: pagination.page,
        current: pagination.page,
        showQuickJumper: true,
        pageSize: pagination.pageSize,
        onShowSizeChange: (current, pageSize) => {
          setPagination({ pageSize });
          history.push(
            `${baseEndpoint}?page=${current}&limit=${pageSize}${tabQuery ? "&tab=" + tabQuery : ""}${
              relatedModelQuery ? "&relatedModel=" + relatedModelQuery : ""
            }${isActiveFilter ? "&isActive=" + isActiveFilter : ""}${search ? "&search=" + search : ""}${
              categoryFilter ? "&category=" + categoryFilter : ""
            }`
          );
        },

        showTotal: (total, range) => <div style={{ color: "gray" }}>{`Total: ${total}`}</div>,
        onChange: (current, pageSize) => {
          setPagination({ page: current, pageSize });
          history.push(
            `${baseEndpoint}?page=${current}&limit=${pageSize}${tabQuery ? "&tab=" + tabQuery : ""}${
              relatedModelQuery ? "&relatedModel=" + relatedModelQuery : ""
            }${isActiveFilter ? "&isActive=" + isActiveFilter : ""}${search ? "&search=" + search : ""}${
              categoryFilter ? "&category=" + categoryFilter : ""
            }`
          );
        },
        showSizeChanger: true
      }}
      rowKey="_id"
      {...props}
    />
  );
}
