import React from "react";
import BykLogo from "../BykLogo";
import StyledImage from "./styles";

export default function BykAnnouncementImage({image, size = "default"}) {
  return (
    <StyledImage>
      {image ? (
        <img className={`${size}`} src={image} alt={`announcement`} />
      ) : (
        <div className={`${size} no-img-found`}>
          <BykLogo size={size} />
        </div>
      )}
    </StyledImage>
  );
}
