import React from "react";
import { FundProjectionScreenOutlined, CopyOutlined, SettingOutlined } from "@ant-design/icons";
import { ROUTES } from "../config/permission";

const sider = () => [
  {
    type: "SubMenu",
    title: "Dashboard",
    basePath: "/dashboard",
    icon: <FundProjectionScreenOutlined />,
    children: [
      {
        type: "Menu",
        title: "Main",
        pathName: ROUTES.Dashboard
      }
    ]
  },
  {
    type: "SubMenu",
    title: "Book",
    basePath: "/book",
    icon: <CopyOutlined />,
    children: [
      {
        type: "Menu",
        title: "List",
        pathName: ROUTES.BookList
      },
      {
        type: "Menu",
        title: "Create",
        pathName: ROUTES.BookCreate
      }
    ]
  },
  {
    type: "SubMenu",
    title: "Settings",
    basePath: "/settings",

    icon: <SettingOutlined />,
    children: [
      {
        type: "Menu",
        title: "Main",
        pathName: ROUTES.SettingsMain
      }
    ]
  }
];

export default sider;
