import React from "react";

import BykContainer from "../../../components/BykContainer/index";
import BykBookTable from "../../../components/Tables/BykBookTable";
import BykTableHeaderBar from "../../../components/BykTableHeaderBar";
import { BASE_ENDPOINT } from "../../../utils";
import usePermit from "../../../hooks/usePermit";
import { D as DOMAINS, CAN } from "../../../config/permission";

function BookList() {
  return (
    <BykContainer>
      <BykTableHeaderBar
        showActiveFilter
        searchPlaceholder={`Search book name, author, tag...`}
        baseEndpoint={BASE_ENDPOINT.book}
        hideCreate={!usePermit({ domain: DOMAINS.book, can: CAN.CREATE })}
      />
      <BykBookTable showAddToTop />
    </BykContainer>
  );
}

export default BookList;
