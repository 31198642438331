import React, { useEffect, useRef, useState } from "react";
import BykContainer from "../../../components/BykContainer";
import { Form, Input, Button, Col, Row, message, Select, Radio } from "antd";
import BykTagInput from "../../../components/BykTagInput";
import BykEditor from "../../../components/BykEditor";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import BykSelect from "../../../components/BykSelect";
import BykForm from "../../../components/BykForm";
import useRedux from "../../../hooks/useRedux";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import BykSubmitButton from "../../../components/Buttons/BykSubmitButton/index";
import BykFormItem from "../../../components/BykFormItem";
import BykCreateButton from "../../../components/Buttons/BykCreateButton";
import BykDivider from "../../../components/BykDivider/index";
import ImgName from "../../../assets/book/name.png";
import ImgAuthor from "../../../assets/book/author.png";
import ImgGeneralInfo from "../../../assets/book/generalInfo.png";
import ImgNarrator from "../../../assets/book/narrator.png";

import { D as DOMAINS, CAN } from "../../../config/permission";
import usePermit from "../../../hooks/usePermit";
import BykCard from "../../../components/BykCard";
import BookImageAndVideoUploadFormItems from "./BookImageAndVideoUploadFormItems";
import BykFileUpload from "../../../components/BykFileUpload/index";
import BykBookImage from "../../../components/BykBookImage/index";
import { dateFormat } from "../../../utils";
import BykAudio from "../../../components/BykAudio/index";
import { bookImageSize3X } from "../../../utils/constants";

function BookForm() {
  const canEditBook = usePermit({
    domain: DOMAINS.book,
    can: CAN.EDIT
  });
  const canCreateBook = usePermit({
    domain: DOMAINS.book,
    can: CAN.CREATE
  });
  const canCreateAuthor = usePermit({
    domain: DOMAINS.author,
    can: CAN.CREATE
  });
  const canUploadImage = usePermit({
    domain: DOMAINS.avatar,
    can: CAN.IMAGE_UPLOAD
  });

  const [form] = Form.useForm();
  const tagsRef = useRef(null);
  const uploadedVideoLengths = useRef({});

  const activeBook = useSelector((state) => state.book.activeBook);
  const getBookPublicDetailLoading = useSelector((state) => state.book.getBookPublicDetailLoading);
  const bookFormLoading = useSelector((state) => state.book.bookFormLoading);

  const [isNewAuthor, setIsNewAuthor] = useState(false);
  const [defaultAuthor, setDefaultAuthor] = useState();

  const { dispatchAction, $ } = useRedux();
  const history = useHistory();
  const location = useLocation();
  const { _id } = useParams();
  const isEditMode = location.pathname.includes("books") && location.pathname.includes("edit");

  useEffect(() => {
    if (activeBook?._id && activeBook?._id === _id) {
      const { name, author, generalInfo, narrator, category } = activeBook;

      form.setFieldsValue({ name, author: author?.name, generalInfo, narrator, category });
    }
  }, [activeBook, _id, form]);

  useEffect(() => {
    if (isEditMode) {
      dispatchAction($.GET_BOOK_PRIVATE_DETAIL_REQUEST, _id);
    }

    return () => dispatchAction($.SET_ACTIVE_BOOK, null);
  }, [$, dispatchAction, isEditMode, _id]);

  const getValueFromEvent = (e) => {
    if (e?.fileList?.length === 0) return null;
    else return e?.fileList;
  };

  const onFinish = async (values) => {
    const { name, author, generalInfo, narrator, category } = values;

    const payload = {
      name,
      author,
      generalInfo,
      narrator,
      _id,
      category
    };

    if (isEditMode) {
      dispatchAction($.UPDATE_BOOK_REQUEST, { book: payload, history });
    } else {
      dispatchAction($.CREATE_BOOK_REQUEST, { book: payload, history });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("RAM ~ file: index.js ~ line 25 ~ onFinishFailed ~ errorInfo", errorInfo);
  };

  if ((!isEditMode && !canCreateBook) || (isEditMode && (!activeBook || activeBook._id !== _id || !canEditBook)))
    return (
      <BykContainer>
        <BykCard addMarginVertical loading={getBookPublicDetailLoading} />
      </BykContainer>
    );

  return (
    <BykContainer>
      <BykCard addMarginVertical style={{ paddingBottom: 30 }}>
        {isEditMode && (
          <Row style={{ marginBottom: 0, paddingBottom: 0 }}>
            <Col
              span={4}
              style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
            >
              <BykFileUpload
                style={{ marginBottom: 10 }}
                buttonText={"Upload Image "}
                icon={"fa fa-image"}
                ActionType={$.GET_BOOK_PRIVATE_DETAIL_REQUEST}
                _id={activeBook._id}
                model={"books"}
                color={"success"}
                mediaType={"image"}
              ></BykFileUpload>
            </Col>
            <Col
              span={8}
              style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}
            >
              <BykBookImage size={"small"} image={activeBook.image} />
              <div style={{ fontSize: 9 }}>{`Width:${bookImageSize3X.width}, Height:${bookImageSize3X.height}`}</div>
            </Col>
            <Col
              span={4}
              style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
            >
              <BykFileUpload
                icon={"fa fa-headphones"}
                buttonText={"Upload Audio(*.mp3)"}
                ActionType={$.GET_BOOK_PRIVATE_DETAIL_REQUEST}
                _id={activeBook._id}
                model={"books"}
                color={"primary"}
                mediaType={"audio"}
              ></BykFileUpload>
            </Col>
            <Col
              span={8}
              style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
            >
              <BykAudio
                key={activeBook?.audio?.location || "1"}
                style={null}
                location={activeBook?._id === _id && activeBook?.audio?.location ? activeBook?.audio?.location : null}
              />
              <div style={{ fontSize: 9 }}>Audio Updated At: {dateFormat(activeBook?.audio?.updatedAt, "-")}</div>
            </Col>
          </Row>
        )}

        {isEditMode && <BykDivider />}
        <BykForm
          form={form}
          loading={bookFormLoading}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{ color: activeBook?.color || "#808080" }}
        >
          <Row justify="end">
            <BykFormItem>
              <BykSubmitButton disabled={bookFormLoading} />
            </BykFormItem>
          </Row>

          <Row gutter={[16, 16]}>
            <Col flex={"auto"}>
              <BykFormItem label="Book Name" name="name" required ruleForCombinedChar>
                <Input />
              </BykFormItem>
            </Col>
            <Col flex="325px">
              <img src={ImgName} style={{ width: 325, height: 125, paddingRight: 5 }} alt="Logo"></img>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col flex={"auto"}>
              <BykFormItem label="Author" name="author" required ruleForCombinedChar>
                <Input />
              </BykFormItem>
            </Col>
            <Col flex="325px">
              <img src={ImgAuthor} style={{ width: 325, height: 125, paddingRight: 5 }} alt="Logo"></img>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col flex={"auto"}>
              <BykFormItem label="General Info" name="generalInfo">
                <Input.TextArea />
              </BykFormItem>
            </Col>
            <Col flex="325px">
              <img src={ImgGeneralInfo} style={{ width: 325, height: 125, paddingRight: 5 }} alt="Logo"></img>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col flex={"auto"}>
              <BykFormItem label="Narrator" name="narrator">
                <Input.TextArea />
              </BykFormItem>
            </Col>
            <Col flex="325px">
              <img src={ImgNarrator} style={{ width: 325, height: 125, paddingRight: 5 }} alt="Logo"></img>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col flex={"auto"}>
              <BykFormItem required label="Category" name="category">
                <Radio.Group>
                  <Radio.Button value="story">Story</Radio.Button>
                  <Radio.Button value="tale">Tale</Radio.Button>
                </Radio.Group>
              </BykFormItem>
            </Col>
            <Col flex="325px">

            </Col>
          </Row>

          <BykFormItem>
            <BykSubmitButton disabled={bookFormLoading} />
          </BykFormItem>
        </BykForm>
      </BykCard>
    </BykContainer>
  );
}

export default BookForm;
