import styled from "styled-components";
import BykCard from "../BykCard";

export const ContainerCard = styled(BykCard).attrs(({ today }) => ({
  bodyStyle: {
    borderWidth: today ? 1 : 0,
    borderStyle: "solid",
    borderColor: "#0dd60d85",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    minHeight: 130
  }
}))``;

export const TitleContainer = styled.div`
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
`;

export const ValueContainer = styled.div`
  font-size: 24px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const PrefixSuffix = styled.div`
  margin-right: 5px;
`;

export const ValueText = styled.div`
  margin: 0px 5px;
`;
