import React from "react";
import { Timeline } from "antd";
import { dateFormat, dateTimeAgo } from "../../utils";

export default function BykDisplayTimestamp({
  firstDateTag = "UPDATED",
  secondDateTag = "CREATED",
  secondDate,
  firstDate,
  small,
  hideTimeAgo = false,
  style,
}) {
  return (
    <div
      style={{
        maxHeight: small ? 53 : 83,
        marginTop: small ? 0 : 6,
        marginBottom: small ? 4 : undefined,
        ...style,
      }}
    >
      <Timeline>
        <Timeline.Item style={{ fontSize: small ? 10 : 12 }} color="green">
          <div>
            <div style={{ fontSize: small ? 6 : 8, color: "gray" }}>
              {firstDateTag}:
            </div>
            <div>{dateFormat(firstDate)}</div>
          </div>
        </Timeline.Item>
        <Timeline.Item style={{ fontSize: small ? 10 : 12 }} color="blue">
          <div style={{ fontSize: small ? 6 : 8, color: "gray" }}>
            {secondDateTag}:
          </div>
          <div>{dateFormat(secondDate)}</div>
          {!hideTimeAgo && (
            <div style={{ fontSize: small ? 6 : 8, color: "gray" }}>
              {dateTimeAgo(secondDate)}
            </div>
          )}
        </Timeline.Item>
      </Timeline>
    </div>
  );
}
