import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import * as $ from "../actionTypes";
import Api from "../../services/Api";
import { $A, showErrorMessage, showWarningMessage, TOKEN } from "../../utils";

const tryLoginSaga = function* ({ payload }) {
  try {
    localStorage.setItem(TOKEN, "");
    const data = yield call(Api.login, payload);

    localStorage.setItem(TOKEN, data.tokenstaff);

    yield put($A($.LOGIN_SUCCESS, data.user));
  } catch (error) {
    showErrorMessage(error, "Login Failed!");
    yield put($A($.LOGIN_FAILURE));
  }
};

const tryAutoLoginSaga = function* () {
  try {
    const token = localStorage.getItem(TOKEN);
    if (token) {
      const data = yield call(Api.autoLogin);
      yield put($A($.AUTO_LOGIN_SUCCESS, data));
    } else {
      yield put($A($.AUTO_LOGIN_FAILURE));
    }
  } catch {
    yield put($A($.AUTO_LOGIN_FAILURE));
  }
};

const tryLogoutSaga = function () {
  try {
    localStorage.setItem(TOKEN, "");
    window.location.href = "/";
  } catch {
    showWarningMessage("Logout Error!");
  }
};

// prettier-ignore
export default function* saga() {
  yield takeEvery($.LOGIN_REQUEST, tryLoginSaga);
  yield takeEvery($.AUTO_LOGIN_REQUEST, tryAutoLoginSaga);
  yield takeLatest($.LOGOUT_REQUEST, tryLogoutSaga);
}
