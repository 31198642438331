import React from "react";
import { Layout } from "antd";
import { useSelector } from "react-redux";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import useRedux from "../../../hooks/useRedux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { activeEnvironment } from "../../../config/env-config";

const { Header } = Layout;

function BykHeader(props) {
  const { dispatchAction, $ } = useRedux();

  const menu = (
    <Menu style={{ marginTop: 7 }}>
      <Menu.Item className="text-center" onClick={() => dispatchAction($.LOGOUT_REQUEST)} key={"logout"}>
        <FontAwesomeIcon icon={faPowerOff} style={{ marginRight: 10 }} />
        Log out
      </Menu.Item>
    </Menu>
  );
  const showEnvProdInLocalhost = activeEnvironment === "production" && window.location.host.includes("localhost");

  const { user } = useSelector((state) => state.auth);
  if (!user) return null;
  return (
    <Header
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
      }}
    >
      <div
        style={{
          backgroundColor: "#ffffff99",
          width: 1,
          height: 20,
          marginLeft: 10
        }}
      ></div>

      <Dropdown overlay={menu} trigger={["click"]}>
        <div style={{ cursor: "pointer" }} onClick={(e) => e.preventDefault()}>
          <div
            style={{
              margin: 10,
              display: "flex",
              flexDirection: "row",
              backgroundColor: showEnvProdInLocalhost ? "crimson" : undefined
            }}
          >
            <FontAwesomeIcon icon={faUserCircle} style={{ color: "#f5e8e8", fontSize: 35, marginRight: 10 }} />

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  color: "white",
                  fontSize: 14,
                  lineHeight: "14px",
                  fontWeight: "bold"
                }}
              >
                {user.email || ""}
                <DownOutlined style={{ color: "white", fontSize: 12, marginLeft: 4 }} />
              </div>
              <div
                style={{
                  color: "white",
                  fontSize: 12,
                  lineHeight: "12px",
                  marginTop: 5
                }}
              >
                {user.role || ""}
                {activeEnvironment !== "production" && (
                  <div
                    style={{
                      fontSize: 10,
                      backgroundColor: "green",
                      textAlign: "center"
                    }}
                  >
                    {activeEnvironment.toUpperCase()} ENVIRONMENT
                  </div>
                )}
                {showEnvProdInLocalhost && (
                  <div style={{ fontSize: 10, textAlign: "center" }}>WARNING ! YOU ARE IN PROD</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Dropdown>
    </Header>
  );
}
export default BykHeader;
