import React from "react";
import {Button, Tooltip} from "antd";
import useHistoryWithNewTab from "../../../hooks/useHistoryWithNewTab";

export default function BykLinkButton({children, tooltipTitle, pathName, className, style = {}, canViewDetail = true}) {
  const history = useHistoryWithNewTab();

  if (!canViewDetail) return children;
  return tooltipTitle ? (
    <Tooltip title={tooltipTitle}>
      <Button
        className={className}
        style={{padding: 0, ...style}}
        type="link"
        onClick={() => history.push(`${pathName || ""}`)}>
        {children}
      </Button>
    </Tooltip>
  ) : (
    <Button
      className={className}
      style={{padding: 0, ...style}}
      type="link"
      onClick={() => history.push(`${pathName || ""}`)}>
      {children}
    </Button>
  );
}
