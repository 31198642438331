import styled from "styled-components";

export default styled.div`
  .name {
    margin-bottom: 8px;
    color: #666;
    font-size: 30px;
    line-height: 40px;
    font-weight: bold;
    text-shadow: none;
  }
  .author {
    color: #666;
    padding: 0 !important;
    font-size: 20px;
    line-height: 40px;
    text-decoration: underline;
  }
  .title {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .row {
    margin: 10px;
    margin-top: 30px;
  }
  .read-listen-container {
    margin-top: 20px;
  }
  .block-container {
    margin-top: 10px;
    color: #666;
  }
  .colorize {
    color: #666;
  }
  .no-video-container {
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 165px 0;
  }
  .no-video-icon {
    font-size: 50px;
    color: #80898f;
  }
`;
