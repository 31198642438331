import React from "react";

export default function BykAudio({location, style = {width: "100%", marginTop: 40, marginBottom: 40}}) {
  if (location) {
    return (
      <audio style={style} key={location || "1"} controls>
        <source src={location} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    );
  } else
    return (
      <audio style={style} key={"2"} controls>
        <source src={location} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    );
}
