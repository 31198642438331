export const sliderSize = { width: 400 * 2, height: 180 * 2 };
export const categorySize = { width: 400 * 2, height: 115 * 2 };

// TODO: Component
export const defaultFormLayout = {
  labelCol: {
    xs: { span: 24 },
    md: { span: 10 }
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 14 }
  }
};

export const STYLE = {
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
};

// for upload
export const bookImageSize = { width: 127, height: 197 };
export const bookImageSize3X = { width: 381, height: 591 };
export const featuredAndCollectionImageSize2x = {
  width: 800,
  height: 600
};
export const announcementImageSize = { width: 610, height: 330 };

export const VIDEO_SIZE = {
  default: {
    width: 280,
    height: 157
  },
  small: {
    width: 280 / 2,
    height: 157 / 2
  },
  xsmall: {
    width: 280 / 3,
    height: 157 / 3
  },
  large: {
    width: 280 * 1.3,
    height: 157 * 1.3
  }
};

const bookImageReal = { width: 127, height: 197 };
// for display
export const IMAGE_SIZE = {
  book: {
    default: {
      width: bookImageReal.width / 3,
      height: bookImageReal.height / 3
    },
    small: {
      width: bookImageReal.width / 2.2,
      height: bookImageReal.height / 2.2
    },
    xsmall: {
      width: bookImageReal.width / 3,
      height: bookImageReal.height / 3
    },
    large: {
      width: bookImageReal.width / 1.3,
      height: bookImageReal.height / 1.3
    }
  },
  collection: {
    default: {
      width: 400,
      height: 300
    },
    small: {
      width: 400 / 3,
      height: 300 / 3
    },
    xsmall: {
      width: 400 / 4,
      height: 300 / 4
    },
    xxsmall: {
      width: 400 / 7,
      height: 300 / 7
    },
    large: {
      width: 300 * 1.3,
      height: 300 * 1.3
    }
  },
  announcementImage: {
    default: {
      width: 610 / 3,
      height: 330 / 3
    },
    small: {
      width: 610 / 4,
      height: 330 / 4
    },
    xsmall: {
      width: 610 / 5,
      height: 330 / 5
    },
    large: {
      width: 610 / 2,
      height: 330 / 2
    }
  }
};

export const SUPPORTED_BLOCK_GROUP_NAMES = {
  HOME: "Home",
  SEARCH: "Search"
};
