import React from "react";
import { Card } from "antd";
import BykSpinner from "../BykSpinner";

const BykCard = ({ children, bodyStyle, style = {}, addMarginVertical, loading }) => {
  return (
    <Card bodyStyle={bodyStyle} style={addMarginVertical ? { marginTop: 15, marginBottom: 15, ...style } : style}>
      {loading ? <BykSpinner /> : children}
    </Card>
  );
};
export default BykCard;
