import store from "../redux/configureStore";

const ROLES = {
  ADMIN: "admin"
};

export const CAN = {
  ACTIVATE: "ACTIVATE",
  VIDEO_UPLOAD: "VIDEO_UPLOAD",
  CREATE: "CREATE",
  DEACTIVATE: "DEACTIVATE",
  DETAIL_VIEW: "DETAIL_VIEW",
  READ_LISTEN: "READ_LISTEN",
  IMAGE_UPLOAD: "IMAGE_UPLOAD",
  SEARCH: "SEARCH",
  EDIT: "EDIT",
  EDIT_INFO: "EDIT_INFO",
  EDIT_BOOKS: "EDIT_BOOKS",
  VIEW_FORM: "VIEW_FORM",
  LIST_VIEW: "LIST_VIEW",
  HARD_DELETE: "HARD_DELETE",
  SORT: "SORT",
  CONFIRM_EMAIL: "CONFIRM_EMAIL",
  MAKE_PREMIUM: "MAKE_PREMIUM",
  CSV_DOWNLOAD: "CSV_DOWNLOAD",
  VIEW_STATS: "VIEW_STATS",
  CACHE_FLUSH: "CACHE_FLUSH",
  LIST_CONFIG: "LIST_CONFIG",
  EDIT_CONFIG: "EDIT_CONFIG",
  VIEW_LOGS: "VIEW_LOGS",
  LIST_USERS: "LIST_USERS",
  FILTER: "FILTER"
};

export const D = {
  dashboard: "dashboard",
  book: "book",
  author: "author",
  category: "category",
  collection: "collection",
  customer: "customer",
  avatar: "avatar",
  promotion: "promotion"
};

// prettier-ignore
export const PERMITS = {
  [ROLES.ADMIN]: {
    [D.dashboard]: [CAN.LIST_VIEW],
    [D.book]: [CAN.LIST_VIEW, CAN.CREATE,CAN.READ_LISTEN, CAN.DETAIL_VIEW, CAN.VIEW_FORM, CAN.SORT, CAN.EDIT, CAN.ACTIVATE, CAN.IMAGE_UPLOAD, CAN.VIDEO_UPLOAD],
  }
};

const routes = {
  Dashboard: "/",
  BookList: "/books",
  BookCreate: "/books/create",
  BookEdit: "/books/:_id/edit",
  BookId: "/books/:_id",
  SettingsMain: "/settings/main"
};

const initialPage = {
  [ROLES.ADMIN]: routes.Dashboard
};

export const ROUTES = routes;

const PERMIT_FOR_ROUTES = {
  [ROLES.ADMIN]: Object.values(routes)
};

export const isRoutePermitted = ({ pathName }) => {
  const { user } = store.getState().auth;
  return PERMIT_FOR_ROUTES[user?.role]?.includes(pathName);
};

export const filterPermittedItems = (routes) => {
  const { user } = store.getState().auth;

  let filteredRoutes = routes.filter(({ path }) => PERMIT_FOR_ROUTES[user?.role]?.includes(path));

  if (!filteredRoutes.find((e) => e.path === ROUTES.Dashboard)) {
    filteredRoutes = [
      {
        path: ROUTES.Dashboard,
        name: "",
        redirect: initialPage[user?.role]
      },
      ...filteredRoutes
    ];
  }

  return filteredRoutes;
};
