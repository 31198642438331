import * as $ from "../actionTypes";

const initialState = {
  activationLoading: false,
  reRenderAfterLanguageChange: false,

  csvData: null,
  csvDataLoading: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case $.INSTANCE_ACTIVATION_REQUEST:
      return { ...state, activationLoading: true };

    case $.RE_RENDER_AFTER_LANGUAGE_CHANGED:
      return {
        ...state,
        reRenderAfterLanguageChange: !state.reRenderAfterLanguageChange,
      };

    case $.INSTANCE_ACTIVATION_SUCCESS:
      return {
        ...state,
        activationLoading: false,
      };

    case $.INSTANCE_ACTIVATION_FAILURE:
      return { ...state, activationLoading: false };

    case $.GET_DATA_TO_CSV_EXPORT_REQUEST:
      return { ...state, csvDataLoading: true };

    case $.GET_DATA_TO_CSV_EXPORT_FINISHED:
      return { ...state, csvDataLoading: false, csvData: payload };

    default:
      return state;
  }
}
