import React from "react";
import { useHistory } from "react-router-dom";

function useHistoryWithNewTab() {
  const [ctrlKeyPressed, setCtrlKeyPressed] = React.useState(false);
  const handleKeyDown = e => {
    if (e.ctrlKey || e.metaKey) {
      setCtrlKeyPressed(true);
    }
  };

  const handleKeyUp = e => {
    if (!e.ctrlKey || !e.metaKey) {
      setCtrlKeyPressed(false);
    }
  };

  const history = useHistory();

  let ctrlHistory = Object.assign({}, history, {
    push: value => {
      if (ctrlKeyPressed) {
        window.open(value);
      } else {
        history.push(value);
      }
    }
  });

  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    window.addEventListener("blur", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
      window.removeEventListener("blur", handleKeyUp);
    };
  }, []);

  return ctrlHistory;
};

export default useHistoryWithNewTab;