import React from "react";
import {Editor, EditorState, getDefaultKeyBinding, RichUtils} from "draft-js";
import "draft-js/dist/Draft.css";
import "./RichEditor.css";

class BykEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {editorState: EditorState.createEmpty()};
    this.focus = () => this.refs.editor.focus();

    this.handleKeyCommand = this._handleKeyCommand.bind(this);
    this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
    this.toggleBlockType = this._toggleBlockType.bind(this);
    this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
  }
  onChange = (parentEditorState) => this.props.onChange(parentEditorState);

  _handleKeyCommand(command, parentEditorState) {
    const newState = RichUtils.handleKeyCommand(parentEditorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }
  _mapKeyToEditorCommand(e) {
    if (e.keyCode === 9) {
      const newEditorState = RichUtils.onTab(e, this.props.parentEditorState, 4);
      if (newEditorState !== this.props.parentEditorState) {
        this.onChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  }
  _toggleBlockType(blockType) {
    this.onChange(RichUtils.toggleBlockType(this.props.parentEditorState, blockType));
  }
  _toggleInlineStyle(inlineStyle) {
    this.onChange(RichUtils.toggleInlineStyle(this.props.parentEditorState, inlineStyle));
  }
  render() {
    const {parentEditorState} = this.props;

    let className = "RichEditor-editor";
    var contentState = parentEditorState.getCurrentContent();

    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== "unstyled") {
        className += " RichEditor-hidePlaceholder";
      }
    }
    return (
      <div className="RichEditor-root">
        <BlockStyleControls editorState={parentEditorState} onToggle={this.toggleBlockType} />
        <InlineStyleControls editorState={parentEditorState} onToggle={this.toggleInlineStyle} />
        <div className={className} onClick={this.focus}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={parentEditorState}
            handleKeyCommand={this.handleKeyCommand}
            keyBindingFn={this.mapKeyToEditorCommand}
            onChange={this.onChange}
            placeholder="Add a some text"
            ref="editor"
            spellCheck={true}
          />
        </div>
      </div>
    );
  }
}
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2
  }
};
function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return null;
  }
}
class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }
  render() {
    let className = "RichEditor-styleButton";
    if (this.props.active) {
      className += " RichEditor-activeButton";
    }
    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}
const BLOCK_TYPES = [
  //   {label: "H1", style: "header-one"},
  //   {label: "H2", style: "header-two"},
  {label: "Header", style: "header-three"},
  //   {label: "H4", style: "header-four"},
  //   {label: "H5", style: "header-five"},
  //   {label: "H6", style: "header-six"},
  //   {label: "Blockquote", style: "blockquote"},
  {label: "Unordered List Item", style: "unordered-list-item"},
  {label: "Ordered List Item", style: "ordered-list-item"}
  //   {label: "Code Block", style: "code-block"}
];
const BlockStyleControls = (props) => {
  const {editorState} = props;
  const selection = editorState.getSelection();
  const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
var INLINE_STYLES = [
  {label: "Bold", style: "BOLD"},
  {label: "Italic", style: "ITALIC"},
  {label: "Underline", style: "UNDERLINE"}
  //   {label: "Monospace", style: "CODE"}
];
const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
export default BykEditor;
