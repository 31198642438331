import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { BASE_ENDPOINT } from "../../utils";
import useHistoryWithNewTab from "../../hooks/useHistoryWithNewTab";

export default function BykBookName({ name, _id, hideIcon, canViewDetail = true, style = {} }) {
  const history = useHistoryWithNewTab();
  if (!canViewDetail) return name;
  return (
    <>
      <p
        onClick={() => history.push(`${BASE_ENDPOINT.book}/${_id}`)}
        style={{
          maxWidth: 240,
          color: "#1890ff",
          background: "transparent",
          borderColor: "transparent",
          WebkitBoxShadow: "none",
          boxShadow: "none",
          cursor: "pointer",
          ...style
        }}
      >
        {name}
      </p>
      {!hideIcon && (
        <a
          style={{ marginLeft: 5, color: "gray", fontSize: 12 }}
          target="_blank"
          rel="noopener noreferrer"
          href={`${BASE_ENDPOINT.book}/${_id || ""}`}
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </a>
      )}
    </>
  );
}
