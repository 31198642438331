import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Row, Col, Table } from "antd";
import useRedux from "../../../hooks/useRedux";
import StyledDetail from "./styles";
import BykContainer from "../../../components/BykContainer/index";
import BykDivider from "../../../components/BykDivider/index";
import BykCard from "../../../components/BykCard";
import BykBookHeader from "../../../components/BykBookHeader";
import { D as DOMAINS, CAN } from "../../../config/permission";
import usePermit from "../../../hooks/usePermit";
import BykAudio from "../../../components/BykAudio/index";

const SPAN_SIZE = 16;
function BookDetail() {
  const activeBook = useSelector((state) => state.book.activeBook);
  const getBookPublicDetailLoading = useSelector((state) => state.book.getBookPublicDetailLoading);
  const user = useSelector((state) => state.auth.user);

  const { dispatchAction, $ } = useRedux();
  const { _id } = useParams();

  const canViewForm = usePermit({ domain: DOMAINS.book, can: CAN.VIEW_FORM });
  const canActivate = usePermit({ domain: DOMAINS.book, can: CAN.ACTIVATE });

  useEffect(() => {
    dispatchAction($.GET_BOOK_PRIVATE_DETAIL_REQUEST, _id);
    return () => {
      dispatchAction($.SET_ACTIVE_BOOK, null);
    };
  }, [dispatchAction, $, _id]);

  if (!activeBook || activeBook?._id !== _id)
    return (
      <BykContainer>
        <StyledDetail>
          <BykCard addMarginVertical loading={getBookPublicDetailLoading}></BykCard>
        </StyledDetail>
      </BykContainer>
    );

  const renderCategories = () => {
    if (activeBook?.category) {
      return (
        <div className={"block-container"}>
          <BykDivider />
          <div className={"title"}>Category: <b>{activeBook.category?.toUpperCase()}</b></div>
        </div>
      );
    }
  };

  const renderInfos = (title, content) =>
    content && (
      <div className={"block-container"}>
        <div className={"title"}>{title}</div>
        <p>{content}</p>
        <BykDivider width={200} />
      </div>
    );

  return (
    <BykContainer>
      <StyledDetail>
        <BykCard addMarginVertical>
          <BykBookHeader
            canDeleteBook={user?.canDeleteBook}
            activeBook={activeBook}
            large
            withTags
            canEdit={canViewForm}
            canActivate={canActivate}
            refreshActions={[{ type: $.GET_BOOK_PRIVATE_DETAIL_REQUEST, payload: _id }]}
          />
          {renderCategories()}

          <BykDivider />

          <BykAudio location={activeBook?.audio?.location} />

          {renderInfos("General Info", activeBook?.generalInfo)}

          <Row gutter={[16, 16]} wrap>
            <Col span={SPAN_SIZE + SPAN_SIZE}>
              <div style={{ marginTop: 20, marginBottom: 10, fontSize: 22, fontWeight: 500 }}>Similar Books</div>
              <Table
                size="small"
                pagination={false}
                dataSource={activeBook?.similarBooks}
                rowKey="_id"
                columns={[
                  {
                    title: "Name",
                    dataIndex: "name",
                    key: "name"
                  },
                  {
                    title: "Author",
                    dataIndex: ["author", "name"],
                    key: "author"
                  },
                  {
                    title: "Narrator",
                    dataIndex: "narrator",
                    key: "narrator"
                  }
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} wrap>
            <Col span={SPAN_SIZE + SPAN_SIZE}>
              <div style={{ marginTop: 20, marginBottom: 10, fontSize: 22, fontWeight: 500 }}>Author Related Books</div>
              <Table
                size="small"
                pagination={false}
                dataSource={activeBook?.authorRelatedBooks}
                rowKey="_id"
                columns={[
                  {
                    title: "Name",
                    dataIndex: "name",
                    key: "name"
                  },
                  {
                    title: "Author",
                    dataIndex: ["author", "name"],
                    key: "author"
                  },
                  {
                    title: "Narrator",
                    dataIndex: "narrator",
                    key: "narrator"
                  }
                ]}
              />
            </Col>
          </Row>
        </BykCard>
      </StyledDetail>
    </BykContainer>
  );
}
export default BookDetail;
