import * as $ from "../actionTypes";

const initialState = {
  cacheLoading: false,
  cacheKeys: [],

  languageConfigs: [],
  languageConfigsLoading: false,
  updateLanguageConfig: false
};

export default function settingsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case $.CACHE_FLUSH_REQUEST:
      return { ...state, cacheLoading: true };

    case $.CACHE_FLUSH_FINISHED:
      return {
        ...state,
        cacheLoading: false
      };

    case $.CACHE_FLUSH_WITH_KEYS_REQUEST:
      return { ...state, cacheLoading: true };

    case $.CACHE_FLUSH_WITH_KEYS_FINISHED:
      return {
        ...state,
        cacheLoading: false
      };

    case $.GET_CACHE_KEYS_FINISHED:
      return {
        ...state,
        cacheKeys: payload
      };

    case $.FETCH_LANGUAGE_CONFIGS_REQUEST:
      return { ...state, languageConfigsLoading: true };
    case $.FETCH_LANGUAGE_CONFIGS_SUCCESS:
      return { ...state, languageConfigsLoading: false, languageConfigs: payload };

    case $.UPDATE_LANGUAGE_CONFIG_REQUEST:
      return { ...state, updateLanguageConfig: true };
    case $.UPDATE_LANGUAGE_CONFIG_SUCCESS:
      return { ...state, updateLanguageConfig: false };

    default:
      return state;
  }
}
