import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/configureStore";
import App from "./App";
import "antd/dist/antd.less";
import { ConfigProvider } from "antd";
import NoDataLogo from "./assets/no_data.svg";

const customizeRenderEmpty = () => (
  <div style={{ textAlign: "center" }}>
    <img alt={"No data found"} src={NoDataLogo} style={{ width: 100, height: "auto", marginBottom: 10 }}></img>
    <p>Data Not Found</p>
  </div>
);

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <App />
      </ConfigProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
