import React, { useEffect } from "react";
import { Row, Col, Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignInAlt,
  faUserPlus,
  faMobileAlt,
  faUsers,
  faChartLine,
  faChild,
  faCertificate,
  faBookOpen,
  faHeadphones
} from "@fortawesome/free-solid-svg-icons";
import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";
import { useSelector } from "react-redux";

import BykStatistic from "../../components/BykStatistic";
import BykInfoIconTooltip from "../../components/BykInfoIconTooltip";
import useRedux from "../../hooks/useRedux";

import { SuffixContainer, PageContainer } from "./styles";
import BykTable from "../../components/Tables/BykTable/index";

const SPAN_SIZE = 6;

export default function Dashboard() {
  const { dispatchAction, $ } = useRedux();

  const bookAnalytics = useSelector((state) => state.analytics.bookAnalytics);
  const bookAnalyticsLoading = useSelector((state) => state.analytics.bookAnalyticsLoading);

  useEffect(() => {
    dispatchAction($.GET_BOOK_ANALYTICS);
  }, [dispatchAction, $]);

  return (
    <PageContainer>
      <Row gutter={[16, 16]} wrap>
        <Col span={SPAN_SIZE}>
          <BykStatistic
            today
            isLoading={bookAnalyticsLoading}
            title="Total Books"
            value={bookAnalytics?.countOfBooks || 0}
            valueStyle={{ color: "#6b6e68" }}
            prefix={<FontAwesomeIcon icon={faBookOpen} />}
          />
        </Col>
        <Col span={SPAN_SIZE}>
          <BykStatistic
            today
            isLoading={bookAnalyticsLoading}
            title="Total Active Books"
            value={bookAnalytics?.countOfActiveBooks || 0}
            valueStyle={{ color: "#3f8600" }}
            prefix={<FontAwesomeIcon icon={faBookOpen} />}
          />
        </Col>

        <Col span={SPAN_SIZE}>
          <BykStatistic
            today
            isLoading={bookAnalyticsLoading}
            title="Total Authors"
            value={bookAnalytics?.countOfAuthor || 0}
            valueStyle={{ color: "#a7741e" }}
            prefix={<FontAwesomeIcon icon={faUsers} />}
          />
        </Col>
        <Col span={SPAN_SIZE}>
          <BykStatistic
            today
            isLoading={bookAnalyticsLoading}
            title="Total Active Authors"
            value={bookAnalytics?.countOfActiveAuthor || 0}
            valueStyle={{ color: "#fca814" }}
            prefix={<FontAwesomeIcon icon={faUsers} />}
          />
        </Col>
        <Col span={SPAN_SIZE}>
          <BykStatistic
            today
            isLoading={bookAnalyticsLoading}
            title="Total Played Count"
            value={bookAnalytics?.totalPlayedBooks || 0}
            valueStyle={{ color: "#54bdef" }}
            prefix={<FontAwesomeIcon icon={faHeadphones} />}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} wrap>
        <Col>
          <div style={{ marginTop: 20, marginBottom: 10, fontSize: 22, fontWeight: 500 }}>Top Played Books</div>
          <Table
            pagination={false}
            dataSource={bookAnalytics?.recentTotalPlayedBooks}
            rowKey="_id"
            columns={[
              {
                title: "Name",
                dataIndex: "name",
                key: "name"
              },
              {
                title: "Author",
                dataIndex: ["author", "name"],
                key: "author"
              },
              {
                title: "Narrator",
                dataIndex: "narrator",
                key: "narrator"
              },
              {
                title: "Played Count",
                dataIndex: "totalPlayedCount",
                key: "totalPlayedCount"
              }
            ]}
          />
        </Col>
      </Row>
    </PageContainer>
  );
}
