import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";

const BykEmailTitle = ({email, emailConfirmed = false, style = {}}) => (
  <a href={process.env.NODE_ENV === "development" ? "#" : "mailto:" + email} style={{marginLeft: "5px", ...style}}>
    {email || ""}
    <FontAwesomeIcon
      style={{
        marginLeft: 5,
        color: emailConfirmed ? "#52c41a" : "gray"
      }}
      icon={emailConfirmed ? faCheckCircle : faTimesCircle}
    />
  </a>
);

export default BykEmailTitle;
