import { put, call, takeLatest, delay } from "redux-saga/effects";
import * as $ from "../actionTypes";
import Api from "../../services/Api";
import { $A, showErrorMessage } from "../../utils";

const tryGetCacheKeysSaga = function* () {
  try {
    const set = new Set();
    for (let i = 0; i < 10; i++) {
      const data = yield call(Api.getCacheKeys);
      for (const key of data.keys) {
        set.add(key);
      }
    }

    yield put($A($.GET_CACHE_KEYS_FINISHED, [...set]));
  } catch (error) {
    showErrorMessage(error, "Cannot Fetch Cache Keys");
  }
};

const tryCacheFlushSaga = function* ({ payload }) {
  try {
    yield call(Api.getCacheFlush);
    yield delay(100);
    yield call(Api.getCacheFlush);
    yield delay(100);
    yield call(Api.getCacheFlush);
    yield delay(100);
    yield call(Api.getCacheFlush);
    yield delay(100);
    yield call(Api.getCacheFlush);
    yield delay(100);
    yield call(Api.getCacheFlush);
    yield delay(100);
    yield call(Api.getCacheFlush);
    yield delay(100);
    yield call(Api.getCacheFlush);
    yield delay(100);
    yield call(Api.getCacheFlush);
    yield delay(100);
    yield call(Api.getCacheFlush);

    yield put($A($.CACHE_FLUSH_FINISHED));
  } catch (error) {
    yield put($A($.CACHE_FLUSH_FINISHED));
  }
};

const tryCacheFlushWithKeysSaga = function* ({ payload }) {
  try {
    for (let i = 0; i < 10; i++) {
      yield call(Api.tryCacheFlushWithKeys, payload);
      yield delay(100);
    }

    yield put($A($.GET_CACHE_KEYS_REQUEST));
    yield put($A($.CACHE_FLUSH_WITH_KEYS_FINISHED));
  } catch (error) {
    yield put($A($.CACHE_FLUSH_WITH_KEYS_FINISHED));
  }
};

// prettier-ignore
export default function* settingsSaga() {

  yield takeLatest($.CACHE_FLUSH_REQUEST, tryCacheFlushSaga);
  yield takeLatest($.CACHE_FLUSH_WITH_KEYS_REQUEST, tryCacheFlushWithKeysSaga);
  yield takeLatest($.GET_CACHE_KEYS_REQUEST, tryGetCacheKeysSaga);

}
