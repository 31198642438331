import React from "react";
import {Button} from "antd";
import {RightSquareOutlined} from "@ant-design/icons";
import useHistoryWithNewTab from "../../../hooks/useHistoryWithNewTab";

export default function BykDetailButton({_id, baseEndpoint}) {
  const history = useHistoryWithNewTab();

  return (
    <Button type="link" onClick={() => history.push(`${baseEndpoint}/${_id || ""}`)}>
      Detail
      <RightSquareOutlined />
    </Button>
  );
}
