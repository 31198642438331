import React from "react";
import Form from "antd/lib/form/Form";
import {Spin} from "antd";

export default function BykForm({children, loading = false, form, onFinish, onFinishFailed, initialValues}) {
  return (
    <Spin spinning={loading}>
      <Form
        initialValues={initialValues}
        requiredMark={"optional"}
        layout="vertical"
        form={form}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        {children}
      </Form>
    </Spin>
  );
}
