import React from "react";
import {Button} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";

function BykCreateButton({style = {}, onClick, shape, children, loading, disabled}) {
  return (
    <Button
      loading={loading}
      disabled={disabled}
      shape={shape}
      style={style}
      type="primary"
      icon={<PlusCircleOutlined />}
      onClick={onClick}>
      {children || "Create"}
    </Button>
  );
}

export default BykCreateButton;
