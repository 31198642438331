import styled from "styled-components";

export default styled.div`
  .delete-cache-container {
    margin-top: 20px;
  }
  .delete-button-container {
    display: flex;
    justify-content: flex-end;
  }
  .cache {
    white-space: pre-wrap; /* CSS3 */
    word-wrap: break-word;
    margin-left: 30px;
  }
  .cache-block {
    cursor: pointer;
    height: 30px;
    width: 100%;
    padding-left: 7px;
    padding-top: 3px;
  }
  .cache-block:hover {
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .delete-button {
    flex-direction: flex-end;
  }
`;
