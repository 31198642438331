import React from "react";
import { aliasToText } from "../../utils";

export default function BykAlias({ alias, withoutParent }) {
  return (
    <div>
      <div style={{ color: "gray", marginLeft: withoutParent ? 15 : 10 }}>
        {" "}
        {aliasToText(alias, withoutParent)}
      </div>
    </div>
  );
}
