import styled from "styled-components";
import { IMAGE_SIZE } from "../../utils/constants";

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .large {
    height: ${IMAGE_SIZE.collection.large.height}px;
    width: ${IMAGE_SIZE.collection.large.width}px;
    border-radius: 5px;
  }
  .default {
    height: ${IMAGE_SIZE.collection.default.height}px;
    width: ${IMAGE_SIZE.collection.default.width}px;
    border-radius: 4px;
  }
  .small {
    height: ${IMAGE_SIZE.collection.small.height}px;
    width: ${IMAGE_SIZE.collection.small.width}px;
    border-radius: 4px;
  }
  .xsmall {
    height: ${IMAGE_SIZE.collection.xsmall.height}px;
    width: ${IMAGE_SIZE.collection.xsmall.width}px;
    border-radius: 3px;
  }
  .xxsmall {
    height: ${IMAGE_SIZE.collection.xxsmall.height}px;
    width: ${IMAGE_SIZE.collection.xxsmall.width}px;
    border-radius: 3px;
  }
  .no-img-found {
    background-color: #f626020d;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .no-img-found-large {
    height: 100px;
  }
  .no-img-found-default {
    height: 80px;
  }
  .no-img-found-small {
    height: 60px;
  }
  .no-img-found-xsmall {
    height: 40px;
  }
`;
