import moment from "moment";
import { message } from "antd";
import countryList from "../assets/countries.json";
const { WHITELIST_CATEGORIES_FOR_ORDERING } = require("@biryudumkitap/kids-localization/categories");

export const secondsToTime = (seconds) => {
  if (!seconds) return { hours: 0, minutes: 0, seconds: 0 };

  const hours = parseInt(seconds / 3600);
  const minutes = parseInt((seconds % 3600) / 60);
  return { hours, minutes, seconds: parseInt(seconds % 60) };
};

export const secondsToTimeString = (seconds) => {
  const time = secondsToTime(seconds);
  return `${time.hours}h ${time.minutes}m ${time.seconds}s`;
};

export const numberWithCommas = (x) => {
  if (!x) return "-";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isPremium = (payment) => {
  return payment && moment(payment.endDate).diff(moment()) > 0;
};

export const $A = (type, payload) => ({
  type,
  payload
});

export const TOKEN = "shv-token";
export const BYK_CURRENT_CONTENT_LANGUAGE = "BykCurrentContentLanguage";

export const displayFullName = (item) => (item ? `${item.firstName || ""} ${item.lastName || ""}` : "");

export const BASE_ENDPOINT = {
  book: "/books",
  author: "/authors",
  customer: "/customers",
  staff: "/staffs",
  collection: "/collections",
  category: "/categories",
  featured: "/featureds",
  block: "/blocks",
  promotion: "/promotions",
  searchTrend: "/search-trends"
};

export const displayVideoLength = (video) => {
  if (video) {
    const ms = video?.length || video;
    return new Date(ms * 1000).toISOString().substr(11, 8);
  } else return "-";
};

export const showErrorMessage = (error, fallbackMessage = "Something went wrong", messagePrefix = "", duration) => {
  const msg = error && error.message ? error.message : error ? error : fallbackMessage;
  message.error(messagePrefix + msg, duration);
};

export const showWarningMessage = (text = "Something went wrong", duration) => message.warning(text, duration);

export const showSuccessMessage = (text = "Success", duration) => message.success(text, duration);

export const dateFormat = (date, placeholder, hideTime) => {
  try {
    return date ? moment(date).format(`DD.MM.YYYY${hideTime ? "" : " - HH:mm"}`) : placeholder ? placeholder : "";
  } catch (error) {
    return "";
  }
};

export const dateTodayTomorrow = (date) => {
  try {
    const today = moment().format("DD.MM.YYYY");
    const tommorrow = moment().add(1, "d").format("DD.MM.YYYY");
    if (moment(today).isSame(date, "day")) {
      return "Today";
    } else if (moment(tommorrow).isSame(date, "day")) {
      return "Tomorrow";
    }
  } catch (error) {
    return "";
  }
};

export const dateTimeAgo = (date) => (date ? moment(date).fromNow() : "");

export const getCountryName = (code) => countryList.find((country) => country.value === code)?.label || code || "-";

export const convertTimeToSeconds = (timeMoment) => {
  if (!moment.isMoment(timeMoment)) return 0;

  const hours = timeMoment.hours();
  const minutes = timeMoment.minutes();
  const seconds = timeMoment.seconds();

  return hours * 3600 + minutes * 60 + seconds;
};

export const convertSecondsToTime = (seconds) => {
  if (seconds) {
    return moment("2020-01-01 00:00:00").add(seconds, "seconds");
  }
  return moment("00:00:00", "HH:mm:ss");
};

export const DEFAULT_HEADERS = () => {
  return {
    bykcontentlanguage: window.sessionStorage.getItem(BYK_CURRENT_CONTENT_LANGUAGE),
    bykBrand: "admin"
  };
};

export const getCurrentContentLanguage = () => window.sessionStorage.getItem(BYK_CURRENT_CONTENT_LANGUAGE);

export const selectOptionForBooks = (books) => {
  const options = books?.map((c) => {
    return {
      value: c?._id,
      label: c?.name + " - " + c?.author?.firstName + " " + c?.author?.lastName + `${c.isActive ? "" : " (Inactive)"}`,
      text: c?.name + " - " + c?.author?.firstName + " " + c?.author?.lastName + `${c.isActive ? "" : " (Inactive)"}`
    };
  });
  return options;
};

export const sortDataByName = (data, isAscending, contentLanguage, sortedKey = "name") => {
  if (data.length === 1) return data;
  const compareFunc = () => {
    const factor = isAscending ? 1 : -1;
    return (a, b) => {
      const name1 = a[sortedKey].toLocaleLowerCase(contentLanguage);
      const name2 = b[sortedKey].toLocaleLowerCase(contentLanguage);

      return name1.localeCompare(name2, contentLanguage) * factor;
    };
  };
  return data.sort(compareFunc());
};

export const convertToSlug = (text) => {
  return text
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export const isEmail = (email) => {
  var re = new RegExp(
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return re.test(String(email).toLowerCase());
};

export const isURL = (str) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const subCategoriesOptions = (subCategoryItem, mainCategoryItem) => {
  return {
    value: subCategoryItem?._id,
    label: `${subCategoryItem?.name} (${mainCategoryItem?.name})`,
    text: `${subCategoryItem?.name} (${mainCategoryItem?.name})`
  };
};

export const aliasToText = (alias, withoutParent) => {
  if (withoutParent) {
    return alias ? `Alias: ${alias}` : "";
  }
  return alias ? ` - (${alias})` : "";
};

export const categoriesOptions = (category) => {
  return {
    value: category?._id,
    label: `${category?.name}${aliasToText(category.alias)}`,
    text: `${category?.name}${aliasToText(category.alias)}`
  };
};

export const updateQueryString = (key, value, customUrl) => {
  let url = customUrl || window.location.pathname + window.location.search;
  var re = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi"),
    hash;

  if (re.test(url)) {
    if (typeof value !== "undefined" && value !== null) {
      return url.replace(re, "$1" + key + "=" + value + "$2$3");
    } else {
      hash = url.split("#");
      url = hash[0].replace(re, "$1$3").replace(/(&|\?)$/, "");
      if (typeof hash[1] !== "undefined" && hash[1] !== null) {
        url += "#" + hash[1];
      }
      return url;
    }
  } else {
    if (typeof value !== "undefined" && value !== null) {
      var separator = url.indexOf("?") !== -1 ? "&" : "?";
      hash = url.split("#");
      url = hash[0] + separator + key + "=" + value;
      if (typeof hash[1] !== "undefined" && hash[1] !== null) {
        url += "#" + hash[1];
      }
      return url;
    } else {
      return url;
    }
  }
};

export const calculateProgressPercent = (current, max) => Math.ceil((current / max) * 100);

export const conversionRateCalculation = (totalIap, totalRegistered) => {
  const totalIapValue = typeof totalIap === "number" ? totalIap : 0;
  const totalRegisteredValue = typeof totalRegistered === "number" ? totalRegistered : 0;
  if (!totalIapValue && !totalRegisteredValue) return 0;

  return ((totalIapValue / totalRegisteredValue) * 100).toFixed(2);
};

export const convertCamelCase = (str) => {
  const arr = str.split("-");
  const capital = arr.map((item, index) =>
    index ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item.toLowerCase()
  );
  return capital.join("");
};

export const convertToSentenceCaseText = (text) => {
  const result = Array.isArray(text) && text.length ? text[text.length - 1] : text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const checkSortable = (categoryName) => {
  const contentLanguage = getCurrentContentLanguage()?.toLowerCase();
  return WHITELIST_CATEGORIES_FOR_ORDERING(contentLanguage).includes(categoryName);
};

export const getNameAndExtensionSeperatly = (text = "") => {
  if (typeof text !== "string") throw new Error("The text must be string!");
  const splittedText = text.split(".");

  return {
    name: splittedText.slice(0, splittedText.length - 1).join("."),
    extension: splittedText[splittedText.length - 1]
  };
};
