import { Button, Col, Row } from "antd";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark, faCalendarCheck, faCalendarPlus, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { FileOutlined, CustomerServiceOutlined } from "@ant-design/icons";

import BykBookImage from "../BykBookImage/index";
import BykCopyable from "../BykCopyable/index";
import BykLinkButton from "../Buttons/BykLinkButton/index";
import BykTag from "../BykTag";
import COLORS from "../../utils/colors";
import BykDivider from "../BykDivider";
import { BASE_ENDPOINT, dateFormat, displayVideoLength, displayFullName } from "../../utils";
import BykActivationSwitch from "../BykActivationSwitch";
import BykEditButton from "../Buttons/BykEditButton";
import BykCreateButton from "../Buttons/BykCreateButton/index";
import useHistoryWithNewTab from "../../hooks/useHistoryWithNewTab";

import StyledHeader from "./styles";
import BykDeleteButton from "../Buttons/BykDeleteButton/index";
import useRedux from "../../hooks/useRedux";
import { useSelector } from "react-redux";

function BykBookHeader({
  activeBook,
  large,
  small,
  withTags,
  withButtons,
  refreshActions,
  canActivate,
  canCreatePart,
  canDeleteBook,
  canEdit
}) {
  const { _id } = useParams();
  const history = useHistoryWithNewTab();
  const { dispatchAction, $ } = useRedux();

  const bookDeleteLoading = useSelector((state) => state.book.bookDeleteLoading);

  if (!activeBook?._id) return null;

  const renderActionButtons = () => (
    <div className={"read-listen-container"}>
      <Button
        type="primary"
        shape="round"
        size={"large"}
        disabled={!activeBook?.video?.length}
        onClick={() => history.push(`/books/${_id}/listen`)}
        icon={<CustomerServiceOutlined />}
      >
        Listen
      </Button>
    </div>
  );

  const renderMeta = () => {
    const renderTotalVideoDuration = () => (
      <div>
        <BykTag icon={<FontAwesomeIcon icon={faPlayCircle} />} color={COLORS.tag.video}>
          {` Audio Duration: ${displayVideoLength(activeBook?.audio?.audioLength)}`}
        </BykTag>
      </div>
    );

    const renderStarted = () => (
      <div>
        <BykTag icon={<FontAwesomeIcon icon={faCalendarCheck} />} color={COLORS.tag.country}>
          {` Total Played Count: ${activeBook.totalPlayedCount || "-"}`}
        </BykTag>
      </div>
    );

    const renderCreatedAt = () => (
      <div>
        <BykTag icon={<FontAwesomeIcon icon={faCalendarPlus} />} color={COLORS.tag.createdAt}>
          {` Created: ${dateFormat(activeBook.createdAt)}`}
        </BykTag>
      </div>
    );
    const renderUpdatedAt = () => (
      <div>
        <BykTag icon={<FontAwesomeIcon icon={faCalendarCheck} />} color={COLORS.tag.updatedAt}>
          {` Updated: ${dateFormat(activeBook.updatedAt)}`}
        </BykTag>
      </div>
    );

    return (
      <Col className={"colorize"}>
        <Row gutter={16}>
          <Col style={{ marginTop: 7 }}>{renderTotalVideoDuration()}</Col>
          <Col style={{ marginTop: 7 }}>{renderCreatedAt()}</Col>
          <Col style={{ marginTop: 7 }}>{renderUpdatedAt()}</Col>
          <Col style={{ marginTop: 7 }}>{renderStarted()}</Col>
        </Row>
      </Col>
    );
  };

  return (
    <StyledHeader>
      <Row className="row">
        <Col span={4}>
          <BykBookImage size={large ? "large" : small ? "small" : "default"} image={activeBook.image} />
        </Col>

        <Col span={16} offset={1}>
          <BykCopyable className={"name"}>{activeBook.name}</BykCopyable>
          <BykCopyable className={"name"}>{activeBook.secondName}</BykCopyable>

          <BykCopyable text={activeBook.author?.name}>
            <BykLinkButton pathName={`${BASE_ENDPOINT.author}/${activeBook?.author?._id || ""}`}>
              <div className={"author"}>{activeBook.author?.name}</div>
            </BykLinkButton>
          </BykCopyable>
          {withButtons ? <BykDivider width={200} /> : null}
          {withButtons ? renderActionButtons() : null}
          {withTags ? <BykDivider width={200} /> : null}
          {withTags ? renderMeta() : null}
        </Col>

        <Col span={3}>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 10
            }}
          >
            <BykActivationSwitch
              messagePrefix={activeBook.name + ": "}
              baseEndpoint={BASE_ENDPOINT.book}
              _id={activeBook._id}
              isActive={activeBook.isActive}
              refreshActions={refreshActions}
              disabled={!canActivate}
            />
          </Row>
          {canEdit ? (
            <Row
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <BykEditButton baseEndpoint={BASE_ENDPOINT.book} _id={activeBook._id} />
            </Row>
          ) : null}
          {canDeleteBook ? (
            <Row
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <BykDeleteButton
                loading={bookDeleteLoading}
                onConfirm={() => {
                  dispatchAction($.DELETE_BOOKS_REQUEST, { _id: activeBook._id, name: activeBook.name });
                }}
              >
                Delete From Database
              </BykDeleteButton>
            </Row>
          ) : null}
        </Col>
      </Row>
    </StyledHeader>
  );
}

export default BykBookHeader;
