import React from "react";
import {Tag, Tooltip} from "antd";

const randomColors = [
  "magenta",
  "green",
  "orange",
  "gold",
  "lime",
  "cyan",
  "red",
  "volcano",
  "blue",
  "geekblue",
  "purple"
];

export default function BykTag({
  randomColorIndex,
  children,
  icon,
  style = {},
  color = "default",
  tooltipTitle,
  onClick = () => {}
}) {
  const COLOR =
    typeof randomColorIndex === "number" && randomColors[randomColorIndex] ? randomColors[randomColorIndex] : color;

  if (tooltipTitle) {
    return (
      <Tooltip title={tooltipTitle}>
        <Tag
          icon={icon}
          color={COLOR}
          style={{
            borderRadius: 10,
            paddingRight: 10,
            paddingLeft: 10,
            paddingTop: 2,
            paddingBottom: 2,
            ...style
          }}>
          {children}
        </Tag>
      </Tooltip>
    );
  }
  return (
    <Tag
      icon={icon}
      color={COLOR}
      onClick={onClick}
      style={{
        borderRadius: 10,
        paddingRight: 10,
        paddingLeft: 10,
        paddingTop: 2,
        paddingBottom: 2,
        ...style
      }}>
      {children}
    </Tag>
  );
}
