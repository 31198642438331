import React from "react";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

export default function BykInfoIconTooltip({ tooltip, loading }) {
  if (!tooltip) return null;

  return (
    <Tooltip title={loading ? "Loading..." : tooltip}>
      <InfoCircleOutlined style={{ marginLeft: 3, marginRight: 7 }} />
    </Tooltip>
  );
}
