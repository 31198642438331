import React, { useEffect, useState } from "react";
import { Select, Spin } from "antd";
import {
  BYK_CURRENT_CONTENT_LANGUAGE,
  categoriesOptions,
  selectOptionForBooks,
  sortDataByName,
  TOKEN
} from "../../utils";
import APP_CONFIG from "../../config/env-config";
import { DEFAULT_HEADERS } from "../../utils/index";

const { Option } = Select;

function BykSelect({ modelName, placeholder, handleOnChange, multiple, defaultValue, disabled, style = {} }) {
  const [data, setData] = useState([]);
  const [value, setValue] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onChange = (value) => {
    setData([]);
    setValue(value);
    setFetching(false);
    handleOnChange(value);
  };

  function requestData(inputValue) {
    //    TODO: MOVE THIS LOGIC TO REDUX AND SAGA
    let searchPath = "";
    let recentPath = "";

    if (modelName === "category") {
      searchPath = `/categories/list-admin${inputValue ? `?search=` + inputValue : ""}`;
      recentPath = "/categories/list-admin?page=1&limit=20";
    } else if (modelName === "book") {
      searchPath = `/search/list-admin${inputValue ? `&search=` + inputValue : ""}`;
      recentPath = "/search/list-admin?page=1&limit=20&all=true";
    } else if (modelName === "author") {
      searchPath = `/authors${inputValue ? `?firstName=` + inputValue : ""}`;
      recentPath = "/authors?page=1&limit=20";
    }

    const fullApiPath =
      !inputValue || !inputValue.trim() ? `${APP_CONFIG.API_PATH}${recentPath}` : `${APP_CONFIG.API_PATH}${searchPath}`;

    return new Promise((resolve, reject) => {
      fetch(fullApiPath, {
        headers: {
          tokenstaff: window.localStorage.getItem(TOKEN)
        }
      })
        .then((res) => {
          res.text().then((data) => {
            if (parseInt(res.status / 100, 10) !== 2) {
              reject({
                ...res,
                _bodyText: data
              });
            } else {
              if (data) {
                const parsedResponse = JSON.parse(data);
                const parsedDataRaw = parsedResponse?.data || parsedResponse;

                const parsedData = parsedDataRaw?.docs || parsedDataRaw;
                let options = [];
                if (modelName === "book") {
                  options = selectOptionForBooks(parsedData);
                } else {
                  options = parsedData.length
                    ? sortDataByName(
                        parsedData.map((item) => {
                          if (modelName === "author") {
                            return {
                              value: item._id,
                              text: `${item.firstName} ${item.lastName || ""}`
                            };
                          } else if (modelName === "category") {
                            return categoriesOptions(item);
                          } else {
                            return {
                              value: item._id,
                              text: item.name
                            };
                          }
                        }),
                        true,
                        window.sessionStorage.getItem(BYK_CURRENT_CONTENT_LANGUAGE),
                        "text"
                      )
                    : [];
                }

                resolve(options);
              } else {
                reject([]);
              }
            }
          });
        })
        .catch((err) => reject(err));
    });
  }
  function onFocus() {
    onSearch();
  }

  const onSearch = async (value) => {
    setData([]);
    setFetching(true);
    const data = await requestData(value); //TODO: add delay
    setData(data);
    setFetching(false);
  };

  return (
    <Select
      mode={multiple ? "multiple" : undefined}
      allowClear
      showSearch
      style={{ width: 400, ...style }}
      placeholder={placeholder || "Select a " + modelName}
      optionFilterProp="children"
      labelInValue
      value={value}
      onFocus={onFocus}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      filterOption={false}
      onSearch={onSearch}
      disabled={disabled}
      onChange={onChange}
    >
      {data.map((d) => (
        <Option key={d.value}>{d.text}</Option>
      ))}
    </Select>
  );
}
export default BykSelect;
