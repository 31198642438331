/**
 * API çağrılarını yazdığımız sınıf adı.
 */
import { showWarningMessage, TOKEN } from "../utils";
import CustomHttpService from "./CustomHttpService";

class Api {
  httpService = new CustomHttpService();

  setHttpService = (httpService) => {
    this.httpService = httpService;
  };

  // PUT request
  _doPut = (endpoint, body) => {
    return this.httpService.fetch({
      method: "put",
      body,
      endpoint
    });
  };

  _doPutWithAuth = (endpoint, body, isFormData = false) => {
    return this.httpService.fetch({
      method: "put",
      body,
      endpoint,
      isFormData,
      headers: { tokenstaff: localStorage.getItem(TOKEN) }
    });
  };
  // POST request
  _doPost = (endpoint, body, isFormData = false) => {
    return this.httpService.fetch({
      method: "post",
      body,
      endpoint,
      isFormData
    });
  };

  _doPostWithAuth = (endpoint, body, isFormData = false) => {
    return this.httpService.fetch({
      method: "post",
      body,
      endpoint,
      headers: { tokenstaff: localStorage.getItem(TOKEN) },
      isFormData
    });
  };

  _doDeleteWithAuth = (endpoint) => {
    return this.httpService.fetch({
      method: "delete",
      endpoint,
      headers: { tokenstaff: localStorage.getItem(TOKEN) }
    });
  };

  // PATCH request
  _doPatch = (endpoint, body) => {
    return this.httpService.fetch({
      method: "patch",
      body,
      endpoint,
      headers: { tokenstaff: localStorage.getItem(TOKEN) }
    });
  };

  // GET request
  _doGet = (endpoint) => {
    return this.httpService.fetch({
      method: "get",
      endpoint
    });
  };

  _doGetWithAuth = (endpoint) => {
    return this.httpService.fetch({
      method: "get",
      endpoint,
      headers: { tokenstaff: localStorage.getItem(TOKEN) }
    });
  };

  isIdNotValid(_id) {
    if (_id && _id.length === 24) return false;

    showWarningMessage(`'${_id}' url not valid`);
    return true;
  }

  objectToQueryString(obj) {
    if (typeof obj !== "object") return "";

    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p) && obj[p]) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&") ? "?" + str.join("&") : "";
  }

  // ANALYTICS
  customerAnalytics = () => {
    return this._doGetWithAuth("/analytics/customer");
  };

  iapAnalytics = () => {
    return this._doGetWithAuth("/analytics/iap");
  };

  bookAnalytics = () => {
    return this._doGetWithAuth("/analytics/dashboard");
  };

  // AUTH
  login = (loginData) => {
    return this._doPostWithAuth("/staffs/login", loginData);
  };

  autoLogin = () => {
    return this._doGetWithAuth("/staffs/profile");
  };

  // BOOK
  fetchBooksList = (payload) => {
    const allStatus = !payload.isActive ? { all: true, noCache: true } : { noCache: true };
    const query = this.objectToQueryString({ ...payload, ...allStatus });
    return this._doGetWithAuth(`/books${query}`);
  };

  createBook = (payload) => {
    return this._doPostWithAuth(`/books`, payload);
  };

  sortBook = ({ _id, books }) => {
    return this._doGetWithAuth(`/books/${_id}/sort`, { books });
  };

  deleteBook = ({ _id }) => {
    return this._doGetWithAuth(`/books/${_id}/delete`);
  };


  getBookPrivateDetail = (_id) => {
    if (this.isIdNotValid(_id)) return { data: null };

    const query = this.objectToQueryString({ isPanel: true, noCache: true });
    return this._doGetWithAuth(`/books/${_id}${query}`);
  };

  updateBook = (payload) => {
    return this._doPutWithAuth(`/books/${payload._id}`, payload);
  };


  // ACTIVATE
  activateInstance = ({ _id, baseEndpoint }) => {
    if (this.isIdNotValid(_id)) return { data: null };
    return this._doGetWithAuth(`${baseEndpoint}/${_id}/activate`);
  };

  deActivateInstance = ({ _id, baseEndpoint }) => {
    if (this.isIdNotValid(_id)) return { data: null };
    return this._doGetWithAuth(`${baseEndpoint}/${_id}/deactivate`);
  };

  // CSV
  getDataToCsvExport = (payload) => {
    return this._doGetWithAuth(payload);
  };


  getCacheKeys = () => {
    return this._doGetWithAuth(`/cache-flush/keys`);
  };
  getCacheFlush = () => {
    return this._doGetWithAuth(`/cache-flush`);
  };
  tryCacheFlushWithKeys = (keys) => {
    return this._doPostWithAuth(`/cache-flush`, keys);
  };
}

export default new Api();
