import React, { useState } from "react";
import { Menu, Layout } from "antd";
import siderRoutes from "../../../screens/siderRoutes.js";
import BykLogo from "../../BykLogo";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import useHistoryWithNewTab from "../../../hooks/useHistoryWithNewTab.js";
import { isRoutePermitted } from "../../../config/permission";

const { Sider } = Layout;
const { SubMenu } = Menu;

const BykSider = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { reRenderAfterLanguageChange } = useSelector((state) => state.general);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const history = useHistoryWithNewTab();
  const location = useLocation();

  const renderMenu = () => {
    const navigateTo = (pathName) => {
      if (pathName) history.push(pathName);
    };

    return siderRoutes().map((item, indx) => {
      if (item.type === "SubMenu") {
        const filteredChildren = item.children.filter((child) =>
          isRoutePermitted(child)
        );
        if (!filteredChildren.length) return null;
        return (
          <SubMenu
            key={`${item.basePath}-${indx}`}
            title={collapsed ? "" : item.title}
            icon={item.icon}
          >
            {filteredChildren.map((child) => (
              <Menu.Item
                onClick={() => navigateTo(child.pathName)}
                key={child.pathName}
              >
                {child.title}
              </Menu.Item>
            ))}
          </SubMenu>
        );
      } else {
        if (!isRoutePermitted(item)) return null;

        return (
          <Menu.Item
            onClick={() => navigateTo(item.pathName)}
            key={item.pathName}
            icon={item.icon}
          >
            {item.title}
          </Menu.Item>
        );
      }
    });
  };
  const currentBasePath = "/" + location.pathname.split("/")[1];

  return (
    <Sider
      key={reRenderAfterLanguageChange}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <BykLogo
          withText={!collapsed}
          style={{ marginTop: 10, marginBottom: 10 }}
        />
      </div>

      <Menu
        theme="dark"
        selectedKeys={[location.pathname]}
        defaultOpenKeys={[currentBasePath]}
        mode="inline"
      >
        {renderMenu()}
      </Menu>
    </Sider>
  );
};

export default BykSider;
