import React from "react";
import { BASE_ENDPOINT, convertToSentenceCaseText, dateFormat, displayFullName, getCountryName } from "./index";
import BykBookImage from "../components/BykBookImage/index";
import BykBookName from "../components/BykBookName/index";
import BykLinkButton from "../components/Buttons/BykLinkButton/index";
import BykDisplayTimestamp from "../components/BykDisplayTimestamp/index";
import BykEditorReadOnly from "../components/BykEditorReadOnly/index";
import { MenuOutlined } from "@ant-design/icons";
import BykCoverImage from "../components/BykCoverImage/index";
import BykAlias from "../components/BykAlias/index";
import BykDetailButton from "../components/Buttons/BykDetailButton/index";
import BykEditButton from "../components/Buttons/BykEditButton/index";
import BykActivationSwitch from "../components/BykActivationSwitch/index";
import BykAnnouncementImage from "../components/BykAnnouncementImage/index";
import { Row, Col } from "antd";
import BykCopyable from "../components/BykCopyable/index";
import BykEmailTitle from "../components/BykEmailTitle/index";

export const generateColumns = (data) => {
  if (!Array.isArray(data)) return;
  return data
    .filter((e) => e.display !== false)
    .map((item) => {
      const { dataIndex, title = convertToSentenceCaseText(dataIndex || ""), type, render, width } = item;

      const defaultFields = {
        key: "table-col-" + dataIndex,
        dataIndex,
        title,
        width
      };
      switch (type) {
        case "bookImage":
          return {
            ...defaultFields,
            title: "",
            align: "center",
            render: (book) => <BykBookImage size={item.size || "small"} image={book?.image} />
          };
        case "email":
          return {
            ...defaultFields,
            render: (data) => <BykEmailTitle email={data?.email} emailConfirmed={data?.emailConfirmed} />
          };
        case "nameWithLink":
          return {
            ...defaultFields,
            render: (dataItem) => {
              const { renderKeys, baseEndpoint } = item;
              if (!Array.isArray(renderKeys)) return;
              const renderName = renderKeys.reduce(
                (currentName, key) => `${currentName ? `${currentName} ` : ""}${dataItem[key] || ""}`,
                ""
              );
              return <BykLinkButton pathName={`${baseEndpoint}/${dataItem._id || ""}`}>{renderName}</BykLinkButton>;
            }
          };
        case "dateFormat":
          return {
            ...defaultFields,
            render: (date) => dateFormat(date)
          };
        case "copyable":
          return {
            ...defaultFields,
            render: (text) => <BykCopyable className={"colorize"}>{text}</BykCopyable>
          };
        case "coverImage":
          return {
            ...defaultFields,
            title: "",
            align: "center",
            render: (image) => <BykCoverImage size={item.size || "xsmall"} image={image} />
          };
        case "announcementImage":
          return {
            dataIndex: "announcement",
            ...defaultFields,
            title: "",
            align: "center",
            render: (image) => {
              return <BykAnnouncementImage size={"small"} image={image} />;
            }
          };
        case "sortIcon":
          return {
            ...defaultFields,
            title: "",
            dataIndex: "_id",
            width: 30,
            render: () => <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
          };

        case "bookName":
          return {
            title: "Name",
            ...defaultFields,
            render: (book) => <BykBookName name={book?.name} _id={book?._id} />
          };

        case "fullNameWithLink":
          return {
            ...defaultFields,
            render: (data, allKeys) => {
              const { baseEndpoint } = item;

              const fullNameObj = data?.name ? data : allKeys;
              return (
                fullNameObj && (
                  <BykLinkButton
                    pathName={`${baseEndpoint ? `${baseEndpoint}/` : ""}${fullNameObj._id || ""}`}
                    canViewDetail={item?.canViewDetail}
                  >
                    {fullNameObj.name}
                  </BykLinkButton>
                )
              );
            }
          };
        case "timestamp":
          const {
            hideTimeAgo,
            style,
            firstDateTag,
            secondDateTag,
            firstDate = "updatedAt",
            secondDate = "createdAt",
            small
          } = item;
          return {
            ...defaultFields,
            title: "Date",
            render: (data) => (
              <BykDisplayTimestamp
                hideTimeAgo={hideTimeAgo}
                firstDateTag={firstDateTag}
                secondDateTag={secondDateTag}
                firstDate={data[firstDate]}
                secondDate={data[secondDate]}
                style={style}
                small={small}
              />
            )
          };
        case "country":
          return {
            ...defaultFields,
            render: (country) => getCountryName(country)
          };
        case "categoryName":
          return {
            ...defaultFields,
            title: "Name",
            dataIndex: "name",
            render: (name, { _id, alias }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <BykLinkButton pathName={`${BASE_ENDPOINT.category}/${_id || ""}`}>{name}</BykLinkButton>
                <BykAlias alias={alias} />
              </div>
            )
          };
        case "draftjs":
          return {
            ...defaultFields,
            render: (content) => (
              <BykEditorReadOnly style={{ maxWidth: 200, maxHeight: 70, overflowX: "hidden" }} content={content} />
            )
          };
        case "isActiveStatus":
          return {
            ...defaultFields,
            render: (status, { _id }) => <BykActivationSwitch _id={_id} isActive={status} disabled />
          };
        case "multipleMeta":
          const { metaFields, style: metaStyle } = item;

          return {
            ...defaultFields,
            title: "Meta",
            align: "center",
            render: (el, data) => (
              <Col style={{ fontSize: 11, color: "#666", ...metaStyle }}>
                {metaFields.map((field) => {
                  const { name, type, customRender, label } = field;
                  let value = data[name];
                  if (type === "dateFormat") {
                    value = dateFormat(data[name]);
                  } else if (type === "fullName") {
                    value = displayFullName(data[name]);
                  } else if (type === "yesNo") {
                    value = value ? "Yes" : "No";
                  }
                  if (type === "customRender") {
                    return customRender(data);
                  }
                  if (!value && value !== 0) return null;
                  return <Row key={name}>{`${label || convertToSentenceCaseText(name)}: ${value}`}</Row>;
                })}
              </Col>
            )
          };
        case "actionButtons":
          const { baseEndpoint, showDetail, showEdit } = item;
          return {
            ...defaultFields,
            title: "",
            dataIndex: "_id",
            render: (_id) => (
              <>
                {showDetail && <BykDetailButton baseEndpoint={baseEndpoint} _id={_id} />}
                {showEdit && <BykEditButton baseEndpoint={baseEndpoint} _id={_id} />}
              </>
            )
          };
        default:
          return {
            ...defaultFields,
            render
          };
      }
    });
};
