const COLORS = {
  white: "white",
  darkerWhite: "#F1F1F1",
  black: "black",
  statusBarColor: "white",
  tabColorBlack: "#505050",
  tabColorGray: "#C7C7C7",
  placeholder: "#ECECEC",

  lineGray: "#DEDEDE",
  darkishBlack: "#484342",
  logoGrayColor: "#423E3C",
  lightishBlack: "#767E8A",
  subtitleGray: "#797979",
  borderGray: "#E8E8E8",
  facebookBgColor: "#3B5998",
  googleBgColor: "#bf4f3f",
  royalBlue: "#0175FF",
  headBlue: "#0175FF",
  pureRed: "#FF0000",
  errorRed: "#FB4C7A",
  heartRed: "#D45755",
  exitRed: "#E91616",
  dark: "#141419",
  darkRead: "#3b464b",
  darkerDarkRead: "#2C3032",
  darkReadContext: "#393d3e",
  searchTextGray: "#B1BCCB",
  explanationBrown: "#7D7371",
  settingsBg: "#faf9f9",
  dividerLight: "#F0F0F0",
  dividerDark: "#eeeeee",
  green: "#2DDD6D",
  emailConfirmGreen: "#28a745",

  grayInfo: "#8E8785",
  backgroundGray: "#F2F2F2",
  disabledButtonBackground: "#dfdfdf",
  disabledButtonText: "#adadad",
  listSubtitleGray: "#B3B3B3",
  filterBackground: "#faf9f9",
  filterResetButtonBorder: "#797979",
  randomColors: [
    "#CBE4F9",
    "#EFF9DA",
    "#F9EBDF",
    "#CDF5F6",
    "#F9D8D6",
    "#E3FFBD",
    "#F7CEE2",
    "#D6CDEA",
    "#F4FFD9",
    "#F3F3F3"
  ],
  tag: {
    part: "orange",
    video: "processing",
    active: "success",
    deactive: "gray",
    favorite: "red",
    createdAt: "purple",
    updatedAt: "#2eb19c",
    listMaker: "#b12e2e",
    country: "#f50",
    birthDate: "rgb(92, 185, 53)",
    deathDate: "rgb(49, 66, 115)",
    premium: {
      isTrialUsed: "#321f28",
      isPremium: "#e79e4f",
      other: "#a05344",
      other2: "#734046"
    }
  }
};

export default COLORS;
