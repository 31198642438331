import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import authReducer from "./auth/authReducer";
import authSaga from "./auth/authSaga";
import bookReducer from "./book/bookReducer";
import bookSaga from "./book/bookSaga";
import generalReducer from "./general/generalReducer";
import generalSaga from "./general/generalSaga";
import analyticsReducer from "./analytics/analyticsReducer";
import analyticsSaga from "./analytics/analyticsSaga";
import settingsReducer from "./settings/settingsReducer";
import settingsSaga from "./settings/settingsSaga";

const combinedSagas = function* () {
  yield all([authSaga(), generalSaga(), bookSaga(), analyticsSaga(), settingsSaga()]);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  auth: authReducer,
  general: generalReducer,
  book: bookReducer,
  analytics: analyticsReducer,
  settings: settingsReducer
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(combinedSagas);

export default store;
